import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useAppRouter } from '@/src/useAppRouter'


export default function Drawer({children, active, position, modal, onClickOutside}) {

  const router = useAppRouter()
  const lastPath = useRef(router.asPath)
  const scrollContainerRef = useRef()


  useEffect(() => {
    if (lastPath.current !== router.asPath) {
      lastPath.current = router.asPath
      scrollContainerRef.current.scrollTo(0,0)
    }
  }, [router.asPath])


  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    if (active === rendered) return

    if (active) {
      setRendered(true)
    } else {
      setTimeout(() => setRendered(false), 500)
    }
  }, [rendered, active])


  const classes = classNames('popover', position, {
    opened: active,
    hidden: !rendered && !active,
  })


  function handleClickOutside (e) {
    if (e.currentTarget !== e.target) return
    if (onClickOutside) onClickOutside()
  }


  return <>
    { !!modal &&
      <div className={classNames("modal-overlay", {active})} onClick={handleClickOutside} />
    }

    <div className={classes}>
      <div className="popover-content relative" ref={scrollContainerRef}>
        {(active || rendered) && children}
      </div>

    </div>

    <style jsx>{`
      .modal-overlay {
        background-color: #000;
        left: 0;
        opacity: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        position: fixed;
        transition: opacity 500ms;
      }
      .modal-overlay.active {
        opacity: 0.7;
        pointer-events: all;
      }
      .popover {
        z-index: 20;
        position: absolute;
        top: 0;
        bottom: -36px; /* for overscroll issue on iOS*/
        width: 100%;
        overflow-y: hidden;
        transition: transform 500ms;
        display: flex;
        flex-direction: column;
        background-color: white;
      }
      :global(.big-drawer-container) .popover,
      :global(.small-drawer-container) .popover {
        bottom: 0;
      }
      .popover.bottom {
        transform: translateY(100vh);
      }
      .popover.right {
        transform: translateX(100vw);
      }
      .popover.left {
        transform: translateX(-100vw);
      }
      .popover.opened {
        z-index: 21;
        pointer-events: all;
      }
      .popover.bottom.opened {
        transform: translateY(0);
      }
      .popover.left.opened,
      .popover.right.opened {
        transform: translateX(0);
        box-shadow: 0 0 4px rgba(0,0,0,0.4);
      }
      .popover.hidden {
        height: 0;
      }
      .popover-content {
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }
      .noscroll .popover-content {
        overflow-y: unset;
      }

    `}</style>
  </>
}
