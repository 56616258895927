import { useAppRouter } from '@/src/useAppRouter'
import classNames from 'classnames'


export default function SetupProgress ({onClose, steps}) {

  const router = useAppRouter()

  async function navigate (href) {
    if (!href) return

    if (onClose) onClose()

    router.push(href)
  }

  async function handleClose() {
    if (onClose) onClose()
  }

  async function handleClick(step) {
    if (step.callback) {
      await step.callback()
    }
    if (step.href) {
      await navigate(step.href)
    }
  }

  return <>
    <div className="content">

      <ol className='rounded-lg overflow-hidden shadow-lg border'>
        {steps.map( (step, i) => {
          const complete = step.complete
          const fake = !!step.fake
          const active = !!(step.href || step.callback)
          const showArrow = !router.query.popover && step.name === steps.find(s => !s.complete)?.name
          return (
            <li className={classNames("relative flex-row", {complete, active, fake})} key={i}>
              { !!showArrow &&
                <div className='current-step-arrow' key='current-step-arrow'>
                  ⬅
                </div>
              }
              <a className={classNames('step-link flex-grow', {pointer: active})} onClick={() => handleClick(step)}>
                {step.name}
              </a>
              { (step.skip && !complete) &&
                <span className="tiny nowrap">
                  <a onClick={step.skip} className="skip-link px-2 py-4 pointer">
                    (Skip)
                  </a>
                </span>
              }
            </li>
          )
        })}
      </ol>

      { onClose &&
        <div className='button-container'>
          <button className='button' onClick={handleClose}>
            Close
          </button>
        </div>
      }
    </div>

    <style jsx>{`
      ol {
        margin: 0;
        background-color: #FFFFFF;
        border: 1px solid #33333311;
        font-size: 0.9rem;
      }
      li {
        border: 1px solid #33333311;
        display: flex;
        flex-direction: row;
        margin: -1px !important;
        counter-increment: item;
        margin-bottom: 5px;
        padding-left: 0.6rem;
        cursor: default;
        text-align: left;
      }
      li.active {
        cursor: pointer;
      }
      .current-step-arrow {
        display: block;
        top: -1.2rem;
        right: 2.7rem;
        font-size: 1.7rem;
        position: absolute;
        pointer-events: none;
        color: #666699;
        z-index: 66;
        transform: rotate(-45deg);
        animation: tap-animation 0.5s;
        animation-timing-function: cubic-bezier(.5, 0.05, 1, 1);
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      @keyframes tap-animation {
        from {
          transform: rotate(-45deg) translate3d(0, 0, 0);
          filter: drop-shadow(2px -1px 1px rgba(0 0 0 / 0.25));
        }
        to {
          transform: rotate(-45deg) translate3d(-4px, 0, 0);
          filter: drop-shadow(1px 0 1px rgba(0 0 0 / 0.1));
        }
      }
      li:before {
        margin-right: 10px;
        content: counter(item);
        background: #666699;
        border-radius: 100%;
        color: white;
        width: 26px;
        height: 26px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
        vertical-align: top;
        margin-top: 0.25rem;
        z-index: 1;
        vertical-align: middle;
        font-weight: 700;
        font-size: 0.9rem;
        line-height: 1.75rem;
        width: 1.75rem;
        height: 1.75rem;
      }
      a.step-link {
        padding: 0.7rem 1rem 0.7rem 0;
        display: inline-block;
        cursor: inherit;
        color: #363636;
        display: block;
        flex-grow: 1;
        margin-left: -3rem;
        padding-left: 3rem;
        font-weight: 500;
        padding: 0.5rem;
        padding-left: 3rem;
        padding-top: 0.71rem;
      }
      a.skip-link,
      a.step-link {
        color: #666699 !important;
      }
      li.active a.step-link {
        color: #485fc7;
      }
      .skip-link {
        display: block;
        padding: 0.8rem 0.5rem 0.55rem !important;
        opacity: 0.6;
      }
      a.skip-link:hover,
      a.step-link:hover {
        background-color: #e1e1ff88;
        opacity: 1;
        text-decoration: none;
      }
      .complete a.step-link:hover,
      .fake a.step-link:hover {
        text-decoration: none;
        background-color: unset;
        opacity: 0.8;
      }
      .complete:before {
        content: '✓';
        background-color: #6FBE44 !important;
        color: white !important;
        opacity: 0.6;
        border-color: #62a73d;
      }
      .complete, .complete:hover, .complete:hover a.step-link {
        background-color: #efe;
      }
      .complete a {
        color: #3c8515DD !important;
      }
    `}</style>
  </>
}
