import { useState } from "react";
import LoadingSpinner from "./LoadingSpinner";
import useRpc from "@/src/useRpc";
import { $, titleize } from "@/lib/string";
import classNames from "classnames";
import SimpleDate from "@/lib/SimpleDate";
import withLoading from "@/src/withLoading";
import rpc from "@/src/rpc";
import CloseIcon from "./icons/CloseIcon";
import Modal from "./Modal";

export default function PlanSelector({org, onClose}) {

  const [info, _infoError, reloadInfo] = useRpc('billing.info')
  const [plans, _plansError, reloadPlans] = useRpc('billing.plans')

  async function handleClose(changed) {
    if (changed) {
      reloadInfo() // NOTE: Not using await to avoid waiting for the reloads
      reloadPlans()
    }

    onClose(changed)
  }

  if (plans && info) {
    return <PlanSelectorUI org={org} plans={plans} info={info} onClose={handleClose} />
  } else {
    return <LoadingSpinner />
  }

}


function PlanSelectorUI({org, plans, info, onClose}) {
  const accountActive = org.status === 'active' || org.status === 'trialing'

  const today = new SimpleDate()
  const inFreeTrial = org.status === 'trialing' || (org.status === 'canceling' && info.trialEndDate > today)
  const currentPlan = accountActive && plans.find(plan => plan.isCurrent)

  const [planId, setPlanId] = useState(accountActive ? currentPlan?.id : null)
  const [showCancelationUI, setShowCancelationUI] = useState(false)
  const [reason, setReason] = useState('')

  const appliesToday = info.nextBillingDate && new SimpleDate(info.nextBillingDate) <= today
  const billingDateHumanized = info.nextBillingDate ? new SimpleDate(info.nextBillingDate).toEnglish() : ''

  const selectedPlan = plans.find(plan => plan.id === planId)

  const changesMade = planId !== currentPlan?.id
  const cancelId = 'cancel'
  const canceling = planId === cancelId

  if (accountActive) {
    const cancelationPlan = {
      id: cancelId,
      name: 'No Plan',
      kind: 'cancel',
      priceInPennies: 0,
      savingsInPennies: 0,
    }

    plans = [...plans, cancelationPlan]
  }

  function kindToAbbreviation(kind) {
    return kind === 'yearly' ? 'yr' : 'mo'
  }

  async function handleSave(e) {
    e.preventDefault()


    if (canceling) {
      setShowCancelationUI(true)
    } else if (!selectedPlan) {
      onClose()
    } else if (changesMade) {
      const method = accountActive ? 'changeSubscription' : 'reactivateSubscription'
      await withLoading(async () => {
        await rpc(`billing.${method}`, {kind: selectedPlan.kind})
      })
      onClose(true)
    } else {
      onClose()
    }
  }


  async function handleCancelation (e) {
    e.preventDefault()
    await withLoading(async () => {
      await rpc('billing.cancelSubscription', {reason})
    })
    setShowCancelationUI(false)
    onClose(true)
  }


  return <>
    <div className="flex-col gap-4">
      <div className='flex-row flex-center spread pb-2 border-bottom'>
        <h2 className='m-1'>Pick Your Plan</h2>
        <button onClick={() => onClose()} className="button is-inverted no-border px-2">
          <CloseIcon/>
        </button>
      </div>

      <ol className="flex-col gap-3">
        {plans.map(plan => {
          const selected = plan.id === planId
          const cancelation = cancelId === plan.id
          const classes = classNames(
            "relative flex-col gap-2 py-2 px-3 rounded-lg border border-gray shadow clickable overflow-hidden",
            {
              'bg-purple': selected,
              'bg-dark-red': selected && cancelation,
              white: selected,
              current: !!plan.isCurrent,
              selected: selected,
            }
          )

          return (
            <li key={plan.id}>
              <label className={classes}>
                {plan.isCurrent &&
                  <span className="bg-ccc dark-gray py-1 opacity-60 text-center text-sm absolute top left full-width">
                    Your Current Plan
                  </span>
                }
                <div className="flex-row gap-2">
                  <input
                    type="radio"
                    name="plan"
                    value={plan.id}
                    checked={planId === plan.id}
                    onChange={() => setPlanId(plan.id)}
                  />
                  <div className="flex-grow flex-row spread">
                    <div className="bold">
                      {plan.name}
                    </div>
                    { cancelation ?
                      <div className="text-sm opacity-50">
                        Cancel Subscription
                      </div>
                    :
                      <div>
                        {$(plan.priceInPennies)}<span className="gray">/{kindToAbbreviation(plan.kind)}</span>
                      </div>
                    }
                  </div>
                </div>
                { !cancelation &&
                  <div className="flex-row spread text-sm">
                    <div>
                      {titleize(plan.kind)} billing
                    </div>
                    <div>
                      {!!plan.savingsInPennies &&
                        <span className={classNames('bold dark-green', {white: selected})}>
                          Saves {$(plan.savingsInPennies)} 🎉
                        </span>
                      }
                    </div>
                  </div>
                }
              </label>
            </li>
          )
        })}

      </ol>

      { appliesToday ?
        <div className="text-sm text-center gray px-2" style={{maxWidth: 320}}>
          Your changes will be applied today.
        </div>
      : info.nextBillingDate ?
        <div className="text-sm gray px-2" style={{maxWidth: 320}}>
          Your plan changes will be applied at the end of your {inFreeTrial ? 'free trial' : 'current billing cycle'} on <span className="nowrap">{billingDateHumanized}</span>.
        </div>
      :
        null
      }

      <div className='flex-col'>
        <button className="button is-primary" onClick={handleSave}>
          { canceling ?
            <span className="bold">Continue</span>
          : changesMade && accountActive ?
            <span className="bold">Save Changes</span>
          : changesMade && !accountActive && selectedPlan ?
            <>
              <span className="bold">Reactivate Now</span>
              { !inFreeTrial &&
                <span>&nbsp;for {$(selectedPlan.priceInPennies)}</span>
              }
            </>
          :
            <span className="bold">Close</span>
          }
        </button>
      </div>
    </div>

    <Modal visible={showCancelationUI} align="top" onClickOutside={() => setShowCancelationUI(false)}>
      <div className="content double-padded" style={{maxWidth: 340}}>
        <h3>Why are you canceling? 😬</h3>
        <form onSubmit={handleCancelation}>
          <p>
            <textarea
              name="reason"
              value={reason}
              onChange={e => setReason(e.currentTarget.value)}
              className="input"
              placeholder="Please let us know what we can do better. Maybe there's something we can do to win you back."
              style={{minHeight: '10rem'}}
            />
          </p>

          <div className="button-container">
            <button className="button is-danger" disabled={!reason}>
              Cancel Account
            </button>
            <button onClick={() => setShowCancelationUI(false)} className="button is-inverted">
              Go Back
            </button>
          </div>

          { appliesToday ?
            <p className='note red'>
              Your account will be canceled today.
            </p>
          :
            <p className='note'>
              If you cancel today, you can continue using your account until the end of your { inFreeTrial ? 'free trial' : 'current subscription'} ({billingDateHumanized}).
            </p>
          }

        </form>
      </div>
    </Modal>



    <style jsx>{`
      li {
        list-style: none;
        margin: 0;
      }
      ol {
        margin: 0 !important;
      }
      input {
        display: none;
      }
      .current {
        padding-top: 2rem !important;
      }
      .selected {
        box-shadow: 0 0 1px 2px white, 0 0 0 6px #277dd2cc !important;
      }

    `}</style>
  </>
}