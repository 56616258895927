import classNames from "classnames"
import { connectTwitter, connectFacebook, connectInstagram, connectLinkedin } from "@/src/actions"
import { titleize } from "@/lib/string"
import SocialIcon from "@/components/icons/legacy/SocialIcon"
import { sendMessage } from "@/src/useMessage"
import useCurrentOrg from "@/src/useCurrentOrg"


export default function SocialConnectionPrep ({platform, onClose}) {

  const org = useCurrentOrg()

  async function handleConnect () {
    const result = await authorizeConnection()
    if (result?.popup) onClose()
  }


  async function authorizeConnection () {
    if (platform === 'facebook')  return connectFacebook()
    if (platform === 'linkedin')  return connectLinkedin()
    if (platform === 'twitter')   return connectTwitter()
    if (platform === 'instagram') return connectInstagram()

    // If all else fails, send them to the settings page
    router.goto('settings')
  }

  if (org && !org.verified) {
    return <>
      <div className="double-padded content center-text" style={{maxWidth: 380}}>
        <h3>First Things First</h3>
        <p>
          Before we connect your {titleize(platform)} account, let&apos;s verify your email address.
        </p>
        <div className="button-container">
          <a className="button is-primary" onClick={() => sendMessage('verifyEmail')}>
            Verify Email Address
          </a>
          <button className="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </>
  }

  return <>
    <div className={classNames("double-padded content", platform)} style={{maxWidth: 380}}>
      <h3>Connecting to {platform === 'twitter' ? <span>X <span className="opacity-40 not-bold">(a.k.a. {titleize(platform)})</span></span> : titleize(platform)}</h3>
      <p>
        To publish your posts to {platform === 'twitter' ? 'X' : titleize(platform)},
        you&apos;ll first need to connect your account.
      </p>

      { platform === 'facebook' ?
        <>
          <p>
            <b>Before you connect, make sure you have</b>:
          </p>
          <ul>
            <li>
              <a className="link" href="https://www.facebook.com/business/help/473994396650734" target="_blank" rel="noreferrer">
                Facebook page for your Business
              </a><br/><span className="note">You cannot publish to personal accounts.</span>
            </li>
          </ul>
        </>
      : platform === 'instagram' ?
        <>
          <p>
            <b>Before you connect, you need</b>:
          </p>
          <ul>
            <li>
              <a className="link" href="https://help.instagram.com/502981923235522" target="_blank" rel="noreferrer">
              Instagram (<b>business</b>) account</a>
              <br/><span className="note">You cannot publish to personal accounts.</span>
            </li>
            <li>
              <a className="link" href="https://www.facebook.com/business/help/473994396650734" target="_blank" rel="noreferrer">
                Facebook page for your Business
              </a>
            </li>
            <li>
              <a className="link" href="https://www.facebook.com/business/help/connect-instagram-to-page" target="_blank" rel="noreferrer">
              Connect your Facebook page to your Instagram (business) account
              </a>.
            </li>
          </ul>
        </>
      :
        null
      }

      <div className="connect-buttons center-text">
        <button className="connect button" onClick={() => handleConnect(platform)}>
          <SocialIcon platform={platform} color="white"/>&nbsp;
          Connect {platform === 'twitter' ? 'to X' : titleize(platform)}
        </button>&nbsp;
        { !!onClose &&
          <button className="button" onClick={onClose}>
            Cancel
          </button>
        }
      </div>

      <p className="center-text padded">
        <a className='link note' href="https://socialjazz.com/contact.html" target="_blank" rel="noreferrer">
          Let us know if you need any help.
        </a>
      </p>

    </div>


    <style jsx>{`
      .note {
        opacity: 0.7;
      }
      a.note {
        opacity: 1;
      }
      li {
        margin-bottom: 1rem;
      }
      .connect-buttons {
        margin-top: 1.5rem;
      }
      .connect-buttons button {
        margin-bottom: 1rem;
      }
      .facebook .connect.button {
        color: white;
        background-color:#1877F2;
      }
      .instagram .connect.button {
        color: white;
        background-color:#E23523;
      }
      .linkedin .connect.button {
        color: white;
        background-color:#0A66C2;
      }
      .twitter .connect.button {
        color: white;
        background-color:#000000cc;
      }
    `}</style>
  </>
}

