import { max } from "@/lib/array";
import classNames from "classnames";
import { useEffect, useRef, cloneElement, useState, useCallback } from "react";

export default function CrossFade ({ children, distance, duration, vertical, reverse}) {

  // WARNING: Cross fade will screw up the cursor position of any editable fields

  distance = distance || 0
  duration = duration || 500

  const container = useRef(null)
  const currentKey = useRef(null)
  const childStack = useRef([])
  const [frame, setFrame] = useState(0) // Used to trigger re-renders
  const [containerHeight, setContainerHeight] = useState(0)

  useEffect(() => {
    if (children?.key === currentKey.current) {
      const kid = childStack.current.find(kid => kid.key === currentKey.current)
      if (kid.status === 'active') {
        kid.content = cloneElement(children)
        setTimeout(() => setFrame(v => v + 1))
      }
      return
    }

    currentKey.current = children?.key

    childStack.current.forEach(kid => {
      if (kid.status === 'active' && kid.key !== currentKey.current) {
        kid.status = 'unmounting'
        kid.removeAt = new Date().valueOf() + duration
      }
    })

    if (children) {
      if (!currentKey.current) {
        console.warn('You must set a key on the nodes passed to CrossFade')
      }
      const newKidOnTheBlock = {
        key: children?.key,
        addedAt: new Date().valueOf(),
        status: 'mounting',
        content: cloneElement(children),
        ref: cloneElement(children),
      }
      childStack.current.unshift(newKidOnTheBlock)
      setFrame(v => v + 1)
    }
  }, [children])


  useEffect(() => {
    adjustHeight()
    childStack.current.forEach(kid => {
      if (kid.status === 'mounting') {
        kid.status = 'active'
        // Increment frame to re-render, so the class can switch to `active`
        setFrame(v => v + 1)
      }
    })
  }, [adjustHeight, frame]) // Pass `frame` so height check fires after each render


  useEffect(() => {
    const timer = setInterval(prune, 1000)
    return () => clearInterval(timer)

    function prune () {
      let changed = false

      childStack.current = childStack.current.filter(kid => {
        const shouldRemove = kid.removeAt && kid.removeAt < new Date().valueOf()
        if (shouldRemove) {
          changed = true
        }
        return !shouldRemove
      })

      if (changed) setFrame(v => v + 1)
    }
  }, [])


  const adjustHeight = useCallback(() => {
    const heights = [0]
    if (container.current) {
      container.current.childNodes.forEach(e => {
        if (e.classList.contains('unmounting')) return
        heights.push(e.offsetHeight)
      })
    }
    setContainerHeight(max(heights))
  }, [])


  useEffect(() => {
    const timer = setInterval(adjustHeight, 100)
    return () => clearInterval(timer)
  }, [adjustHeight])

  return <>
    <div className={classNames("container")} ref={container} style={{height: containerHeight}}>
      { childStack.current.map(kid => {
        return (
          <div className={classNames("kid", kid.status)} key={[kid.key, kid.addedAt].join('-')}>
            {kid.content}
          </div>
        )
      }) }
    </div>

    <style jsx>{`
      .container {
        position: relative;
        width: 100%;
        transition: all ${duration}ms;
      }
      .kid {
        top: 0;
        position: absolute;
        transition: all ${duration}ms ease-out;
        width: 100%;
      }
      .mounting {
        transform: translate${vertical ? 'Y' : 'X'}(${distance * (reverse ? 1 : -1)}px);
        opacity: 0;
      }
      .active {
        transform: translate${vertical ? 'Y' : 'X'}(0px);
        opacity: 1;
        transition: all ${duration}ms ${duration * 0.5}ms  ease-out;
      }
      .unmounting {
        transform: translate${vertical ? 'Y' : 'X'}(${distance}px);
        opacity: 0;
      }
    `}</style>
  </>

}
