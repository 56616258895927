import { useState } from "react"
import DeleteIcon from "./icons/legacy/DeleteIcon"


export default function UserForm ({user, onSubmit, onCancel, onRemove, allowRemove, allowEdits, formLabel}) {

  formLabel ||= 'Invite'

  const [firstName, setFirstName] = useState(user.firstName || '')
  const [lastName,  setLastName ] = useState(user.lastName  || '')
  const [email,     setEmail    ] = useState(user.email     || '')

  const oldEmail = user.email
  const existingUser = !!user.id
  const canRemove = allowRemove && existingUser && user.role !== 'owner'
  const emailDisabled = existingUser && oldEmail

  const canSave = !!email


  let emailToolTip = ''
  if (emailDisabled) {
    if (canRemove) {
      emailToolTip = "If you want to change the email, remove this person from the team, and then re-invite them with the new email address."
    } else {
      emailToolTip = "The email address cannot be changed."
    }
  }

  async function handleSubmit (e) {
    e.preventDefault()
    onSubmit({...user, firstName, lastName, email, oldEmail})
  }

  return <>
    <div className="spread">
      <h2>
        { user.me ?
          'Your Info'
        : existingUser ?
          'Edit Info'
        :
          `${formLabel} a Team Member`
        }
      </h2>
      {canRemove &&
        <span className="remove-button" onClick={onRemove} title="Remove this Person from the Team">
          <span className="text">Remove from Team</span>
          <DeleteIcon/>
        </span>
      }
    </div>
    <form method="POST" onSubmit={handleSubmit}>

      <div className="spread">

        <div className="field">
          <label className="label">
            First Name
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="firstName"
              autoComplete={existingUser ? "given-name" : "false"}
              value={firstName}
              onInput={e => setFirstName(e.currentTarget.value)}
              placeholder="Michael"
              disabled={!allowEdits}
            />
          </div>
        </div>

        <div className="field" style={{marginLeft: 8}}>
          <label className="label">
            Last Name
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="lastName"
              autoComplete={existingUser ? "family-name" : "false"}
              value={lastName}
              onInput={e => setLastName(e.currentTarget.value)}
              placeholder="Scott"
              disabled={!allowEdits}
            />
          </div>
        </div>

      </div>

      <div className={"field " + (emailToolTip ? 'show-title-on-hover' : '')} title={emailToolTip}>
        <label className="label">
          Email Address
        </label>
        <div className="control">
          <input
            className="input"
            type="email"
            name="email"
            autoComplete="false"
            value={email}
            onInput={e => setEmail(e.currentTarget.value)}
            placeholder={"mike@example.com"}
            disabled={emailDisabled || !allowEdits}
          />
        </div>
      </div>

      <br/>

      { allowEdits ?
        <>
          <div className="button-container">
            <button className="button is-primary" disabled={!canSave}>
              {existingUser ? 'Save' : formLabel === 'Invite' ? 'Send Invite' : 'Add User'}
            </button>
            <button className="button " onClick={onCancel}>
              Cancel
            </button>
          </div>
        </>
      :
        <div className="button-container">
          <button className="button " onClick={onCancel}>
            Close
          </button>
        </div>
      }
    </form>

    <style jsx>{`
      form {
        text-align: left;
        margin-top: 1rem;
      }
      .note {
        margin: 1rem 0;
        font-size: 0.9rem;
      }
      .remove-button {
        cursor: pointer;
        position: relative;
      }
      .remove-button .text {
        display: none;
        font-size: 0.9rem;
        color: red;
        position: absolute;
        right: 1.5rem;
        top: 0.2rem;
        white-space: nowrap;
        pointer-events: none;
      }
      .remove-button:focus .text,
      .remove-button:hover .text {
        display: inline-block;
      }
      button {
        min-width: 140px;
      }
    `}</style>
  </>
}
