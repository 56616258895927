import SimpleDate from "@/lib/SimpleDate"
import { datesInMonth, indexInWeek } from "@/src/misc"
import { useMessage } from "@/src/useMessage"
import useRpc from "@/src/useRpc"
import classNames from "classnames"


export function MiniMonth ({date}) {

  date = new SimpleDate(date)

  const days = datesInMonth(date)

  const startOfMonth = date.addDays(-1 * (date.dayOfMonth - 1))

  // Fetch postGroup ids and dates quickly, so the calendar can show something
  const [sparsePostGroups, _, reload] = useRpc('postGroups.between', {
    startDate: startOfMonth,
    endDate:   startOfMonth.addMonths(1),
    idsAndDatesOnly: true,
  })

  useMessage('refreshCalendar', () => reload())

  return <>
    <div className="mini-month-view full-height flex-col">
      <div className="text-center month-name">
        <span className="sm_hidden">
          { date.monthName.substring(0, 3) }
        </span>
        <span className="sm_contents">
          { date.monthName }
        </span>
      </div>

      <div className="days-of-month flex-grow">
        { days.map((d, i) => {
          const postGroups = sparsePostGroups ? sparsePostGroups.filter(pg => pg.date === d.toString()) : []

          const style = {}
          if (i === 0) style.gridColumnStart = indexInWeek( d ) + 1
          const classes = classNames("day flex-row flex-center", {'has-posts': postGroups.length > 0})

          return (
            <div key={d.toString()} className={classes} style={style} draggable="false" >
              <div className={classNames("day-number")}>
                { d.dayOfMonth }
              </div>
            </div>
          )

        }) }
      </div>
    </div>

    <style jsx>{`
      .mini-month-view {
        background-color: #e6e6e6;
        width: 100%;
        height: 100%;
      }
      .days-of-month {
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        gap: 1px;
      }
      .days-of-month {
        height: 100%;
        align-content: stretch;
      }
      .day {
        background-color: white;
        position: relative;
      }
      .day-number {
        color: #666699;
        background-color: #ffffffcc;
        text-align: center;
        font-size: 0.6rem;
        line-height: 0;
        pointer-events: none;
      }
      .day.has-posts {
        background-color: #c5e5b4;
      }
      .month-name {
        font-size: 1rem;
        color: #666;
        font-weight: bold;
      }
      @media screen and (max-width: 1000px) {
        .day-number {
          font-size: 0.4rem;
        }
      }
      @media screen and (max-width: 765px) {
        .month-name {
          font-size: 0.9rem;
        }
      }
      @media screen and (max-width: 400px) {
        .month-name {
          font-size: 0.8rem;
        }
        .day-number {
          display: none;
        }
      }
      @media screen and (max-height: 500px) {
        .month-name {
          font-size: 0.8rem;
        }
        .day-number {
          display: none;
        }
      }
    `}</style>
  </>
}
