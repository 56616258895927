import Toggle from "@/components/Toggle";
import SocialIcon from "@/components/icons/legacy/SocialIcon";
import classNames from "classnames";
import { sortBy } from "@/lib/array";
import ExternalLinkIcon from "./icons/ExternalLinkIcon";
import Link from "next/link";
import WarningIcon from "./icons/WarningIcon";
import { titleize } from "@/lib/string";


export default function DestinationPicker({available, selectedIds, onChange, disabled, posts}) {

  // After the posts have been sent, then we use their destinations, instead of
  // the list of currently active dsetinations, because these posts may have
  // been sent to destinations that are no longer active.
  if (disabled && posts) {
    available = posts.map(post => post.destination).filter(dest => dest.targetId)
  }

  function isEnabled (destination) {
    return selectedIds.includes(destination.id)
  }

  function toggleEnabled (destination) {
    if (disabled) return

    let newIds = []

    if (isEnabled(destination)) {
      newIds = selectedIds.filter(id => id !== destination.id)
    } else {
      newIds = [...selectedIds, destination.id]
    }

    onChange(newIds)
  }


  return <>

    <section>
      <div className="content">
        { sortBy(available, ['platform', 'name']).map(destination => {
          const post = posts.find(post => post.destinationId === destination.id)

          const publishedUrl = post?.publishedUrl
          const publishErrorMessage = post?.publishErrorMessage

          // TODO: Explain why posts with no image/video were not published to instagram

          return (
            <div className="flex-row" key={destination.id}>
              <div className={`control-group border px-1 py-3 spread flex-grow flex-align-start relative`}>
                { publishedUrl ?
                  <Link href={publishedUrl}>
                    <a className="absolute-fill pointer clickable" target="_blank" rel="noopener noreferrer">
                      <div className='tooltip-container'>
                        <div className='tooltip' style={{left:'unset', right: 25, top: -30}}>
                          Open {titleize(destination.platform)} Post
                        </div>
                      </div>
                    </a>
                  </Link>
                : !disabled ?
                  <div onClick={() => toggleEnabled(destination)} className="absolute-fill z-up pointer">
                  </div>
                :
                  null
                }
                <div className="flex-row gap-2">
                  <div className="flex-row gap-2 overflow-hidden px-2 flex-align-start">
                    <div className="flex-no-shrink">
                      <SocialIcon platform={destination.platform} />
                    </div>
                    <span className="ellipsis overflow-hidden" style={{marginTop: '-0.1rem'}}>
                      {destination.name}
                    </span>
                  </div>

                </div>
                <div>
                  { publishedUrl ? (
                      <div className="px-2">
                        <div className="text-sm text-blue-600 underline">
                          <ExternalLinkIcon/>
                        </div>
                      </div>
                    )
                  : publishErrorMessage ?
                    <div
                      className={classNames("px-2 error-message nowrap bg-hover pointer")}
                      onClick={() => {}}
                      title={`Publishing Failed: ${publishErrorMessage}`}
                    >
                      <WarningIcon/>
                    </div>
                  : disabled ?
                    <div className={classNames("px-2 gray nowrap bg-hover pointer")} >
                      <div className='tooltip-container'>
                        <div className='tooltip' style={{left:'unset', right: 30, top: -6}}>
                          Not Published
                        </div>
                      </div>
                      <WarningIcon/>
                    </div>
                  :
                    <div className="">
                      {/* No onChange... click is handled by the parent element (with absolute-fill) */}
                      <Toggle checked={isEnabled(destination)} disabled={disabled}/>
                    </div>
                  }
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>

    <style jsx>{`
      .control-group {
        margin: auto;
        text-align: left;
        max-width: 300px;
        margin-bottom: -1px;
      }
    `}</style>
  </>
}
