import rpc from "@/src/rpc"
import { useState } from "react"


export default function HelpSettings () {

  const [submitted, setSubmitted] = useState()
  const [error, setError] = useState()
  const [message, setMessage] = useState('')

  async function handleSubmit (e) {
    e.preventDefault()
    if (!message) return
    try {
      await rpc('users.requestHelp', {message})
      setSubmitted(true)
      setError('')
    } catch (err) {
      setError(err.message || '')
      console.error(err)
    }
  }

  return <>
    <section className="settings px-4">
      { submitted ?
        <>
          <h4>Message Sent 👍</h4>
          <p>
            We&apos;ll be in touch soon.
          </p>
        </>
      :
        <form onSubmit={handleSubmit}>
          <p>
            We&apos;d love to hear from you.
          </p>

          { error &&
            <p className="error-message">
              😩 Message failed. Please try again.
            </p>
          }

          <textarea
            value={message}
            onChange={e => setMessage(e.target.value)}
            className="textarea"
            placeholder="Type your question or comment here"
          />

          <button className="button is-primary my-2" disabled={!message}>
            Send Message
          </button>

          <div className="gray mt-6">
            Or if you prefer the "old fashion" way, just email us at <a className="link" href="mailto:support@socialjazz.com">
              support@socialjazz.com
            </a>
          </div>
        </form>
      }

    </section>

    <style jsx>{`
    `}</style>
  </>
}
