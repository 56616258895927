import rpc from "@/src/rpc"
import { useRef } from "react"
import Toggle from "./Toggle"
import useHighlight from "@/src/useHighlight"
import useRpc from "@/src/useRpc"


export default function NotificationSettings () {

  const [user, _, reload] = useRpc('users.me')

  const statusRef = useRef(null)
  useHighlight('status', statusRef)

  const marketingRef = useRef(null)
  useHighlight('marketing', marketingRef)

  async function toggleNotification (type) {
    await rpc('users.toggleNotification', {type})
    await reload()
  }

  return <>
    <section className="settings">
      <h4 className="p-1">Which emails do you want to receive?</h4>

      <div className="settings-group" ref={marketingRef}>
        <div className="spread">
          <label>
            Marketing
          </label>
          <Toggle checked={user?.wantsMarketingEmails || false} onChange={() => toggleNotification('marketing')}/>
        </div>
        <div className="small gray">
          Tips, news, and special offers.
        </div>
      </div>

      <div className="settings-group" ref={statusRef}>
        <div className="spread">
          <label>
            Weekly Updates
          </label>
          <Toggle checked={user?.wantsStatusEmails || false} onChange={() => toggleNotification('status')}/>
        </div>
        <div className="small gray">
          To review your upcoming posts.
        </div>
      </div>
    </section>

    <style jsx>{`
      section {
        margin: 1rem 0;
        padding: 0 1rem;
        padding-bottom: 12rem;
      }

    `}</style>
  </>
}
