import classNames from "classnames"


const WHITESPACE = /\s/

export default function StyledCaption ({caption}) {
  if (!caption) return null

  let current = {hashtag: false, value: ''}
  let chunks = []

  for (let i = 0; i < caption.length; i++) {
    const prev = caption[i - 1]
    const ch = caption[i]
    const next = caption[i + 1]
    const startOfHashtag = (
      !current.hashtag &&
      (i === 0 || WHITESPACE.test(prev))
      && ch === '#'
      && isHashTagCharacter(next)
    )
    if (!current.hashtag && startOfHashtag) {
      if (current.value.length) chunks.push(current)
      current = {hashtag: true, value: ''}
    } else if (current.hashtag && !isHashTagCharacter(ch)) {
      chunks.push(current)
      current = {hashtag: false, value: ''}
    }
    current.value += ch
  }

  if (current.value.length) chunks.push(current)

  return <>
    {chunks.map((c, i) => {
      const classes = classNames("chunk", {
        hashtag: c.hashtag,
      })

      return <span className={classes} key={i} >{c.value}</span>
    })}

    {/*
      NOTE: Need to add a non-breaking space to force the correct height when
      the caption has a trailing newline character, otherwise the "textarea"
      will not resize correctly when editing captions.
    */}
    <span>&nbsp;</span>

    <style jsx>{`
      .chunk { }
      .hashtag { color: #4793DA; }
    `}</style>
  </>
}


function isHashTagCharacter (ch) {
  if (!ch) return false
  return (
    (ch >= 'a' && ch <= 'z') ||
    (ch >= 'A' && ch <= 'Z') ||
    (ch >= '0' && ch <= '9')
  )
}
