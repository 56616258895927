import { useState } from "react"
import SimpleDate from "@/lib/SimpleDate"
import Modal from "./Modal"


const validTimeString = /^\d\d:\d\d/


export default function TimePicker ({onSubmit, onClose, value, date, preventPublish}) {
  const now = new Date()
  const today = new SimpleDate()

  const [timeValue, setTimeValue] = useState( defaultTimeValue() )
  const [schedulingOption, setSchedulingOption] = useState('time')
  const [showPastTimeError, setShowPastTimeError] = useState(false)

  const scheduledAt = timeValueToDate(timeValue)

  const minTime = new Date(now.valueOf())
  minTime.setSeconds(0)
  minTime.setMilliseconds(0)

  const inPast = scheduledAt && scheduledAt < minTime

  const canPublishNow = new SimpleDate(date).equals(today) && !preventPublish

  const canSave = (
    schedulingOption === 'now'   ||
    (schedulingOption === 'time' && !!scheduledAt)
  )


  function defaultTimeValue () {
    if (value) {
      return isoToTimeString(value)
    } else {
      // Schedule it a bit in the future
      let soon = new Date(now)
      soon.setMinutes(soon.getMinutes() + 10)

      // Then "round up" to the next 15 minute boundary
      soon.setMinutes(soon.getMinutes() + 15 - (soon.getMinutes() % 15))

      let defaultTime = soon
      if (defaultTime.getDate() !== now.getDate()) {
        defaultTime = now
      }

      return dateToTimeString(defaultTime)
    }
  }


  function timeValueToDate (newTime) {
    if (!validTimeString.test(newTime)) return null

    const hours   = parseInt(newTime.split(':')[0], 10)
    const minutes = parseInt(newTime.split(':')[1], 10)

    if (hours   < 1 || hours   > 24) return null
    if (minutes < 0 || minutes > 59) return null

    let d = new SimpleDate(date)
    // if (hours < now.getHours() || (hours === now.getHours() && minutes < now.getMinutes()))

    let t = new Date(d.year, d.month - 1, d.dayOfMonth)

    t.setHours(hours)
    t.setMinutes(minutes)
    t.setSeconds(0)
    t.setMilliseconds(0)

    return t
  }


  function handleSave () {
    if (!canSave) {
      alert('Invalid time')
      return
    }

    let value = null
    if (schedulingOption === 'now') {
      value = 'now'
    } else if (schedulingOption === 'time') {
      value = scheduledAt.toISOString()
      if (inPast) {
        setShowPastTimeError(true)
        return
      }
    }
    onSubmit(value)
  }


  function handleCancel () {
    onClose()
  }


  return <>
    <div className="container padded flex-col gap-4">
      <h2 className="text-lg bold center">
        Schedule Post
      </h2>

      <div className="option">
        <label className="radio">
          <input
            type="radio"
            name="publish-time"
            value="time"
            style={{visibility: canPublishNow ? 'visible' : 'hidden'}}
            checked={schedulingOption === 'time'}
            onChange={e => {
              if (e.target.checked) setSchedulingOption(e.target.value)
            }}
          /> <span>Publish at</span>&nbsp;
          <input
            type="time"
            value={timeValue}
            min={dateToTimeString(minTime)}
            max="23:59"
            style={{fontSize: '1rem', marginLeft: '0.25rem'}}
            onChange={e => {
              setTimeValue(e.target.value)
              setSchedulingOption('time')
            }}
          />
        </label>
      </div>

      { canPublishNow &&
        <div className="option">
          <label className="radio">
            <input
              type="radio"
              name="publish-time"
              value="now"
              checked={schedulingOption === 'now'}
              onChange={e => {
                if (e.target.checked) setSchedulingOption(e.target.value)
              }}
            /> <span>Publish Right Now</span>
          </label>
        </div>
      }

      <div className="flex-row flex-center gap-2">
        <button className="button is-primary" onClick={handleSave} disabled={!canSave}>
          {schedulingOption === 'now' ? 'Publish' : 'Save'}
        </button>
        <button className="button" onClick={handleCancel}>
          Cancel
        </button>
      </div>

    </div>

    <Modal visible={showPastTimeError} onClickOutside={() => setShowPastTimeError(false)}>
      <div className="content padded center-text">
        <h2>
          Time machine not found 😜
        </h2>
        <p>
          Please pick a time after {humanTime(minTime)}
        </p>
        <button className="button is-primary" onClick={() => setShowPastTimeError(false)}>
          OK
        </button>
      </div>
    </Modal>

    <style jsx>{`
      label {
        padding: 0 0.4rem;
        display: flex;
        align-items: center;
      }
      input[type="radio"] {
        margin: 0.25rem;
      }
      button {
        min-width: 100px;
      }
    `}</style>
  </>
}


// Convert a timestamp into the format that works for <input type="time">
// Namely, hh:mm (in 24-hour time)
function isoToTimeString (value) {
  if (typeof value !== 'string') return value

  return dateToTimeString(new Date(value))
}


function dateToTimeString (date) {
  return [
    date.getHours().toString().padStart(2, '0'),
    date.getMinutes().toString().padStart(2, '0'),
  ].join(':')
}


function humanTime(t) {
  if (!t) return t
  return t.toLocaleTimeString().replace(/:\d\d /, ' ')
}
