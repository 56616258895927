import { useEffect, useState } from 'react'
import Link from 'next/link'
import HomeScreenDesktop from './HomeScreenDesktop.js'
import HomeScreenPhone from './HomeScreenPhone.js';
import useRpc from '@/src/useRpc.js';
import Modal from '@/components/Modal.js';
import useDeviceInfo from '@/src/useDeviceInfo.js';
import VerifyEmailForm from './VerifyEmailForm.js';
import { sendMessage } from '@/src/useMessage.js';
import { logout, switchToOrg } from '@/src/session.js';
import { MonthView } from './MonthView.js'
import TopNav from './TopNav.js'
import useCurrentOrg, { refreshCurrentOrg } from '@/src/useCurrentOrg.js'
import { useAppRouter } from '@/src/useAppRouter.js'
import useCurrentUser from '@/src/useCurrentUser.js'
import LoadingSpinner from './LoadingSpinner.js'
import rpc from '@/src/rpc.js';


export default function HomeScreen ({date}) {

  const router = useAppRouter()

  const org = useCurrentOrg()
  const user = useCurrentUser()
  const [orgs] = useRpc('orgs.mine')

  const waitingForAI = org?.aiContentNeeded
  useEffect(() => {
    if (!waitingForAI) return
    const timer = setInterval(refreshCurrentOrg, 1000 * 15)
    return () => clearInterval(timer)
  }, [waitingForAI])

  const [phoneLayout, setPhoneLayout] = useState(true)

  const {width, mobile, portrait} = useDeviceInfo()
  useEffect(() => {
    setPhoneLayout(width <= 750 || (mobile && portrait))
  }, [width, mobile, portrait])


  async function handleSignOut () {
    await logout()
    router.push('/login')
  }


  async function createDemoOrg() {
    sendMessage('loading', true)
    const {org: newOrg, url} = await rpc('orgs.createDemo')
    sendMessage('loading', true)
    await switchToOrg(newOrg)
    if (url) {
      window.location.href = url
    } else {
      window.location.reload()
    }
  }


  if (!orgs || !user) {
    return <>
      <div className='p-4 text-center'>
        <LoadingSpinner size="medium"/>
      </div>
    </>
  }

  if (user && orgs.length === 0 && !org) {
    return <>
      <Modal visible={true} align="top" marginTop={100}>
        <div className='p-4'>
          <h2 className='text-xl mb-4 text-center bold'>
            { (user.canCreateDemos || user.canGoBackstage) ?
              'No organizations found'
            :
              'Access Denied'
            }
          </h2>
          { user.canCreateDemos &&
            <div>
              <button className='button is-green full-width mb-2' onClick={createDemoOrg}>
                Create a Demo Account
              </button>
            </div>
          }
          <div className='flex-row gap-2 flex-center'>
            <button className='button is-blue' onClick={handleSignOut}>
              Sign Out
            </button>
            { user.canGoBackstage &&
              <Link href='/backstage'>
                <a className='button is-purple'>
                  Go Backstage
                </a>
              </Link>
            }
          </div>
        </div>
      </Modal>
    </>
  }

  if (org && !org.verified && !user?.canConnectToOrgs) {
    return <>
      <Modal visible={true} onClickOutside={() => sendMessage('focusLoginCode')}>
        <VerifyEmailForm required/>
      </Modal>
    </>
  }

  if (org?.aiContentNeeded || org?.blocked) {
    return <>
      <div className='flex-col absolute-fill'>
        <div className="top-nav shadow-lg" style={{zIndex: 1001}}>
          <TopNav date={date} showMonthNav={false}/>
        </div>
        <div className='flex-grow relative'>
          <div className='absolute-fill ui-locked prevent-long-press noselect pointer-events-none'>
            <MonthView date={date}/>
          </div>
          <Modal visible noBackground noPortal marginTop='4rem'>
            <div className='p-4'>
              <div className="content padded" style={{maxWidth: 350}}>
                { org?.blocked ?
                  <>
                    <h2>Social Jazz is Growing Fast 😳</h2>
                    <p>
                      To make sure that our existing customers get the best service
                      possible, we&apos;re putting new signups on hold as we
                      scale up our team and infrastructure.
                    </p>
                    <p>
                      We&apos;ll let you know as soon as we&apos;re ready for
                      you to join the party.
                    </p>
                    <p>
                      <a className='link' href="mailto:hello@socialjazz.com">Let us know if you have any questions</a>.
                    </p>
                  </>
                :
                  <>
                    <h2>Almost Ready</h2>
                    <p>
                      Your social media plan is in the works and will be ready for your review soon.
                    </p>
                    <div className='center border bg-eee p-2 mt-2 mb-3 rounded-lg'>
                      <LoadingSpinner/>
                      Creating Posts
                    </div>
                    <p>
                      In the meantime, feel free to <a className='link' href="mailto:hello@socialjazz.com">reach out if you have any questions</a>.
                    </p>
                  </>
                }
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  }

  if (user) {
    return <HomeScreenView date={date} phoneLayout={phoneLayout}/>
  } else {
    return null
  }
}


function HomeScreenView({date, phoneLayout}) {
  if (phoneLayout) {
    return <HomeScreenPhone date={date}/>
  }
  else {
    return <HomeScreenDesktop date={date}/>
  }

}
