import { useEffect } from "react"
import { useAppRouter } from "./useAppRouter"

// Will highlight a DOM node if the `highlight` query param is set to `name`
export default function useHighlight (name, ref) {
  const router = useAppRouter()

  useEffect(() => {
    if (router.query.highlight === name) {
      setTimeout(() => {
        router.replace({pathname: router.pathname, query: {...router.query, highlight: ''}})
        const ui = ref.current
        if (ui) sendMessage('highlightElement', ui)
      }, 500)
    }
  }, [router.query.highlight, name, ref, router])
}
