import rpc from "@/src/rpc"
import classNames from "classnames"
import { useState } from "react"
import { sendMessage } from '@/src/useMessage'
import Modal from './Modal'
import UserForm from './UserForm'
import { sortBy } from '@/lib/array'
import useRpc from '@/src/useRpc'
import useCurrentOrg from "@/src/useCurrentOrg"


export default function UserManagementSettings () {

  const org = useCurrentOrg()
  const [editing, setEditing] = useState(null)
  const [recentlyAddedId, setRecentlyAddedId] = useState()
  const [user] = useRpc('users.me')
  const [users, _, reload] = useRpc('users.allForOrg')

  const adminRole = user?.role === 'admin'
  const ownerRole = user?.role === 'owner'

  const verified = org?.verified
  const orgNameMissing = org && !org.name
  const canManageUsers = adminRole || ownerRole || user?.canConnectToOrgs
  const canAddUsers = org && !orgNameMissing && (verified || user?.canConnectToOrgs) && canManageUsers
  const canRemoveUsers = canAddUsers

  function editUser(user) {
    setEditing(user)
  }

  async function addUser (newUser) {
    const user = await rpc('users.addUserToOrg', newUser, {throwErrors: true})
    reload()
    setRecentlyAddedId(user.id)
    setTimeout(() => setRecentlyAddedId(null), 4000)
  }

  async function saveUser(user) {
    try {
      if (user.id) {
        const {me, email, oldEmail} = user
        await updateUser(user)
        setEditing(null)
        if (me && !oldEmail) {
          sendMessage('verifyEmail', email)
        }
      } else {
        await addUser(user)
        setEditing(null)
      }
    } catch (err) {
      console.error(err)
      let message = err.userMessage || (user.id ? 'Update failed' : 'Invite failed')
      sendMessage('error', message)
    }
  }

  async function updateUser (user) {
    const {id, firstName, lastName} = user
    user = await rpc('users.updateUser', {id, firstName, lastName})
    reload()
  }

  async function removeUser (user) {
    setEditing(null)
    await rpc('users.removeUserFromOrg', {email: user.email})
    reload()
  }

  if (!users) return null

  return <>
    <section className="settings">

      { canManageUsers &&
        <>
          {(orgNameMissing || !verified)  &&
            <>
              <p className='warning'>
                Before you invite anyone to the team, you need
                to { verified ?
                    <a className="link" onClick={() => sendMessage('editOrg')}>
                      set the company name
                    </a>
                  :
                    <a className="link" onClick={() => setEditing(users[0])}>
                      add your email address
                    </a>
                  }.
              </p>
            </>
          }

          <div className="center-text">
            <button className="button is-primary" disabled={!canAddUsers} onClick={() => setEditing({})}>
              Add a Team Member
            </button>
          </div>
        </>
      }

      <div className="user-list">
        {sortBy(users, u => u.firstName || u.lastName || u.email).map(user =>
          <div className={classNames("user spread", {recent: user.id === recentlyAddedId})} key={user.id} onClick={() => editUser(user)}>
            <div className='name-and-email'>
              <span className="name">
                { (user.firstName || user.lastName) ?
                  <>{user.firstName} {user.lastName}</>
                :
                  <span className='missing'>No Name</span>
                }
              </span>
              <span className="email" title={user.email}>
                {user.email || <span className='missing'>Email Unknown</span> }
              </span>
            </div>
            { user.id === recentlyAddedId ?
                <div className="invite-sent">
                  { org?.status === 'lead' ?
                    <>Added</>
                  :
                    <>Invite&nbsp;Sent</>
                  }
                </div>
            : user.me ?
              <span className="you">You</span>
            : user.role === 'owner' ?
              <span className="owner">Owner</span>
            :
              null
            }
          </div>
        )}
      </div>

      <Modal visible={!!editing}>
        {!!editing &&
          <div className='double-padded content'>
            <UserForm
              user={editing}
              onCancel={() => setEditing(null)}
              onSubmit={saveUser}
              onRemove={() => removeUser(editing)}
              allowRemove={canRemoveUsers}
              allowEdits={canManageUsers || editing.id === user.id}
              formLabel={org?.status === 'lead' ? 'Add' : 'Invite'}
            />
          </div>
        }
      </Modal>

    </section>

    <style jsx>{`
      section {
        padding: 0.5rem 1rem;
        padding-bottom: 5rem;
      }
      a {
        text-decoration: underline;
      }
      .warning {
        padding: 0.5rem 1rem;
        background-color: #ffa;
        border: 1px solid #ee3;
      }
      .invite-sent, .you, .owner {
        font-style: italic;
        color: #666;
        padding: 0 1rem;
        font-size: 0.8rem;
      }
      .remove.button {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-size: 0.85rem;
        opacity: 0.6;
      }
      .remove.button:hover {
        opacity: 1;
      }
      .user-list {
        margin: 1rem 0;
      }
      .user {
        align-items: center;
        border-top: 1px solid #ddd;
        transition: background-color 250ms;
        cursor: pointer;
      }
      .user:last-child {
        border-bottom: 1px solid #ddd;
      }
      .user:hover {
        background-color: #E3F0FA;
      }
      .user.recent {
        background-color: #ffa;
      }
      .name-and-email {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        max-width: 80%;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      .email {
        font-size: 0.8rem;
      }
      .add-user {
        margin: 1rem 0;
      }
    `}</style>
  </>
}
