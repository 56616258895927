import SimpleDate from "@/lib/SimpleDate"
import LeftChevronIcon from "./icons/LeftChevronIcon"
import RightChevronIcon from "./icons/RightChevronIcon"
import Link from "next/link"
import { useAppRouter } from "@/src/useAppRouter"


export default function MonthViewNavigation ({date}) {

  const router = useAppRouter()

  date = new SimpleDate(date)
  const today = new SimpleDate()

  const todayUrl     = router.path(today)
  const prevMonthUrl = router.path(date.addMonths(-1))
  const nextMonthUrl = router.path(date.addMonths(1))

  const todayDisabled = today.month === date.month && today.year === date.year

  return <>
    <div className="controls p-2 flex-row flex-center spread mx-auto full-width" style={{maxWidth: 350}}>

      <Link href={todayUrl}>
        <a className="button" disabled={todayDisabled}>
          Today
        </a>
      </Link>

      <div className="inline-block text-lg sm_text-xl text-center nowrap">
        { date.monthName } { date.year }
      </div>

      <div className="shadow inline-block nowrap">
        <Link href={prevMonthUrl}>
          <a className="button no-shadow px-2" style={{borderRadiusRight: 0}}>
            <LeftChevronIcon />
          </a>
        </Link>
        <Link href={nextMonthUrl}>
          <a className="button no-shadow px-2" style={{borderRadiusLeft: 0, marginLeft: -1}}>
            <RightChevronIcon />
          </a>
        </Link>
      </div>

    </div>
  </>
}
