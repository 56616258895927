import Image from 'next/image'
import MonthViewNavigation from "./MonthViewNavigation"
import AccountMenu from './AccountMenu.js';


export default function TopNav({date, showMonthNav}) {

  return (
    <>
      <div className='flex-col'>
        <nav className="navigation" role="navigation" aria-label="main navigation">
          <div className='px-1 py-2 flex-no-shrink'>
            <Image src="/img/logo.svg" alt="Social Jazz" width={100} height={40}/>
          </div>

          { showMonthNav &&
            <div className='flex-grow month-nav'>
              <MonthViewNavigation date={date} />
            </div>
          }

          <div className='flex-row overflow-hidden'>
            <AccountMenu/>
          </div>

        </nav>
      </div>

      <style jsx>{`
        nav {
          height: 56px;
          width: 100%;
          box-shadow: 0 0 4px rgba(0,0,0,0.5);
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          background-color: white;
        }
      `}</style>
    </>
  )
}
