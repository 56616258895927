import rpc from '@/src/rpc.js';
import useCurrentOrg from '@/src/useCurrentOrg.js';
import { useAppRouter } from '@/src/useAppRouter.js';
import { useEffect } from 'react';
import HomeScreen from '../components/HomeScreen'
import LoginRequired from '@/components/LoginRequired'
import SimpleDate from '@/lib/SimpleDate'


export default function HomePage({date}) {
  const router = useAppRouter()
  const org = useCurrentOrg()

  // Resume the signup, if it is still in progress
  useEffect(() => {
    if (org) checkForIncompleteSignup()

    async function checkForIncompleteSignup () {
      const url = await rpc('signup.nextUrl')
      if (url) {
        router.push( url )
      }
    }
  }, [org, router])

  return <>
    <LoginRequired>
      <HomeScreen date={date}/>
    </LoginRequired>
  </>
}


// Force server-side render (SSR) to avoid glitches with SSG of calendar
export async function getServerSideProps({query}) {
  return {
    props: {date: query.date || new SimpleDate().toString()},
  }
}
