import { useEffect, useRef, useState } from 'react'
import AddPost from './AddPost'
import rpc from '@/src/rpc'
import PostGroupEditor from './PostGroupEditor'


export default function PostPanel ({date}) {

  const [activePost, setActivePost] = useState()

  const [platformsAvailable, setPlatformsAvailable] = useState([])

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    fetchSocialPlatforms()

    return () => mounted.current = false

    async function fetchSocialPlatforms () {
      const status = await rpc('social.status')
      if (!status) return setTimeout(fetchSocialPlatforms, 500)

      const {accounts} = status
      const platformsEnabled = accounts.filter(acct => acct.enabled).map(acct => acct.platform)
      if (mounted.current) setPlatformsAvailable(platformsEnabled)
    }
  }, [])

  function handleAddPost(post) {
    setActivePost({...post, platforms: platformsAvailable})
  }

  return <>
    <div className='post-panel-wrapper full-height overflow-hidden flex flex-col'>
      <div className='padded-panel bg-eee overflow-auto-y full-height flex-grow'>
        { activePost ?
          <PostGroupEditor
            date={date}
            post={activePost}
            onClose={() => setActivePost(null)}
          />
        :
          <AddPost date={date} onEdit={handleAddPost} />
        }
      </div>
    </div>

    <style jsx>{`
      @media screen and (max-width: 765px) {
        .padded-panel {
          padding-bottom: 4rem !important;
        }
      }
      @media screen and (min-width: 900px) {
        .post-panel-wrapper {
          width: 850px;
        }
      }

    `}</style>
  </>
}
