import classNames from "classnames"

export default function ArrowIcon ({direction, color}) {

  const classes = classNames('icon', direction)

  return <>
    <svg className={classes} style={{color}} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentColor" fill="none" strokeWidth="2" fillRule="nonzero" strokeLinecap="round" strokeLinejoin="round">
        <path d="M2,8 L14,8 L10,4 M14,8 L10, 12"/>
      </g>
    </svg>

    <style jsx>{`
      .icon {
        color: #666699;
        margin: auto;
        vertical-align: middle;
      }
      .left {
        transform: rotate(180deg);
      }
      .down {
        transform: rotate(90deg);
      }
    `}</style>
  </>
}