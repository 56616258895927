import { sleep } from "@/src/misc"
import classNames from "classnames"
import { useRef, useState } from "react"


// Toggle (on/off switch)
export default function Toggle ({onChange, checked, disabled, label, bold}) {

  const [toggled, setToggled] = useState(checked || false)
  const debounceTime = useRef(0)
  const [propsMasked, setPropsMasked] = useState(false)

  async function handleChange (e) {
    if (isDebounced()) return

    setToggled(!checked)
    debounce()
    setPropsMasked(true)

    // Slight delay to give the animation time to run
    await sleep(150)
    if (onChange) await onChange()
    setPropsMasked(false)
  }


  // Ignore additional clicks (for a little while)
  function debounce() {
    const now = new Date().valueOf()
    debounceTime.current = now + 250
  }


  function isDebounced() {
    const now = new Date().valueOf()
    return debounceTime.current > now
  }


  const isChecked = propsMasked ? toggled : checked

  return <ToggleControl checked={isChecked} onChange={handleChange} disabled={disabled} label={label} bold={bold} />
}


function ToggleControl ({onChange, checked, disabled, label, bold}) {

  // TODO: When disabled, add a little lock icon onto the toggle UI

  return <>
    <label className={classNames("toggle-control flex-row gap-2 flex-align-center", {disabled: !!disabled, bold: !!bold})}>
      <input
        type="checkbox"
        name="toggle"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className="toggle"></span>
      { label }
    </label>


    <style jsx>{`
      .toggle-control {
        cursor: pointer;
      }

      .toggle-control.disabled {
        cursor: not-allowed;
        color: #ddd;
      }

      .toggle {
        position: relative;
        display: flex;
        width: 36px;
        height: 22px;
        background-color: #ddd;
        border-radius: 30px;
        box-shadow: inset 0 2px 4px -2px rgba(32, 22, 22, 0.3);
        margin-left: 0.5rem;
        line-height: 0;
      }

      .toggle:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: white;
        top: 2px;
        left: 2px;
        transition: all 150ms;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.6);
      }

      input:checked + .toggle::after {
        left: 16px;
      }

      input:checked + .toggle {
        background-color: #3499CC;
      }

      input {
        display : none;
      }

      input:focus-visible + .toggle {
        box-shadow: 0 0 0.15rem 0.15rem #fff, 0 0 0.05rem 0.3rem currentColor;
      }

      input:disabled + .toggle {
        background-color: #eee;
        border-bottom: 1px solid #eee;
        box-shadow: inset 0 2px 2px -2px rgba(32, 22, 22, 0.2);
      }

      input:disabled + .toggle:after {
        background-color: #fff;
        box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
      }

      input:checked:disabled + .toggle {
        background-color: #3499CC88;
      }
    `}</style>
  </>
}
