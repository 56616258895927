import { useAppRouter } from '@/src/useAppRouter'
import CloseIcon from './icons/CloseIcon'
import SimpleDate from '@/lib/SimpleDate'
import useDeviceInfo from "@/src/useDeviceInfo";
import { ordinalize } from '@/lib/string';
import LockClosedIcon from './icons/LockClosedIcon';
import Link from 'next/link';


export default function PostGroupLocked ({date, onClose}) {

  const router = useAppRouter()

  const {mobile} = useDeviceInfo()

  const bottomBarStyle = {}
  if (mobile) {
    bottomBarStyle.position = 'fixed'
    bottomBarStyle.left = 0
    bottomBarStyle.right = 0
    bottomBarStyle.bottom = 36
  }

  async function handleClose () {
    if (onClose) {
      onClose()
    } else {
      router.goto(date)
    }
  }

  let pgDate = null
  try {
    pgDate = new SimpleDate(date)
  } catch (err) {
    console.warn(`Couldn't parse date ${date}`)
    console.error(err)
  }


  return <>
    <div className='post-group-editor bg-eee flex flex-col' style={{maxHeight: 'calc(100vh - 2rem)'}}>
      <div className='flex-row spread bg-white shadow z-1 px-2'>
        <div className='flex-row flex-grow flex-same'>
        </div>

        <div className='flex flex-shrink'>
          {pgDate ?
            <span>
              {pgDate.monthName.substring(0, 3)} {ordinalize(pgDate.dayOfMonth)}, {pgDate.year}
            </span>
          :
            'Locked Post'
          }
        </div>

        <div className='flex-row flex-grow flex-same justify-flex-end'>
          <div className="flex p-2 my-1 pointer bg-hover-light-gray" data-testid="close-post-group-button" onClick={handleClose}>
            <CloseIcon/>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-auto-y">

        <div className="flex-row gap-1 bg-yellow orange mt--1px border-yellow flex-start flex-justify-center px-2 py-3">
          <div className="flex-no-shrink flex">
            <LockClosedIcon/>
          </div>
          <span>This post is locked.</span>
        </div>

        <div className='p-2 pb-4'>
          <div className='flex-col gap-4'>
            <div className='py-5 px-6 rounded text-center dark-gray'>
              This post is pretty great, but we can't show you (yet).
              <br/><br/>
              It will be unlocked after you <Link href={router.path('subscription')}>
                <a className='link'>add your payment method</a>
              </Link>.
            </div>
          </div>
        </div>
      </div>

    </div>

    <style jsx>{`
      .bg-hover-light-gray {
        border: 1px solid transparent;
        border-radius: 0.5rem;
        transition: all 150ms;
      }
      .bg-hover-light-gray:hover {
        background-color: #eee;
        border-color: #ddd;
      }
    `}</style>
  </>
}
