import { get as apiGet, post as apiPost } from "./api";
import { jazzlinkMode, openPopup } from "./misc";
import rpc from "./rpc";
import { refreshApiData } from "./useApi";
import { sendMessage } from "./useMessage";


export async function connectFacebook () {
  const popup = jazzlinkMode()
  try {
    sendMessage('loading', true)
    const url = await rpc('social.facebookAuthUrl')
    if (popup) {
      openPopup(url)
      return {popup: true}
    } else {
      window.location.href = url
    }
  } catch (err) {
    // TODO: Show some kind of error message in the UI
    console.error(err)
  } finally {
    // In case the redirect fails
    setTimeout(() => { sendMessage('loading', false) }, 3000)
    return {popup}
  }
}


export async function connectInstagram () {
  const popup = jazzlinkMode()
  try {
    sendMessage('loading', true)
    const url = await rpc('social.instagramAuthUrl')
    console.log(url)
    if (popup) {
      openPopup(url)
    } else {
      window.location.href = url
    }
  } catch (err) {
    // TODO: Show some kind of error message in the UI
    console.error(err)
  } finally {
    // In case the redirect fails
    setTimeout(() => { sendMessage('loading', false) }, 3000)
    return {popup}
  }
}


export async function connectLinkedin () {
  const popup = jazzlinkMode()
  try {
    sendMessage('loading', true)
    const res = await apiGet(`/orgs/:orgId/linkedin/getAuthUrl`)
    const {url, error, message} = await res.json()
    if (error) throw Error(message)
    if (popup) {
      openPopup(url)
    } else {
      window.location.href = url
    }
  } catch (err) {
    // TODO: Show some kind of error message in the UI
    console.error(err)
  } finally {
    // In case the redirect fails
    setTimeout(() => { sendMessage('loading', false) }, 3000)
    return {popup}
  }
}


export async function connectTwitter () {
  const popup = jazzlinkMode()
  try {
    sendMessage('loading', true)
    const res = await apiGet(`/orgs/:orgId/twitter/getAuthUrl`)
    const {url, error, message} = await res.json()
    if (error) throw Error(message)
    if (popup) {
      openPopup(url)
      return {popup: true}
    } else {
      window.location.href = url
    }
  } catch (err) {
    // TODO: Show some kind of error message in the UI
    console.error(err)
  } finally {
    // In case the redirect fails
    setTimeout(() => { sendMessage('loading', false) }, 3000)
    return {popup}
  }
}
