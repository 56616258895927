import rpc from "@/src/rpc"
import { toSentence, uniq } from "@/lib/array"
import { useEffect, useState } from "react"
import TrashIcon from "./icons/TrashIcon"
import Modal from "./Modal"
import classNames from "classnames"
import EditIcon from "./icons/EditIcon"
import useDeviceInfo from "@/src/useDeviceInfo"
import LoadingSpinner from "./LoadingSpinner"
import LoadingOverlay from "./LoadingOverlay"
import { formattedPhoneNumber } from "@/src/misc"
import Checkbox from "./Checkbox"
import useCurrentOrg, { refreshCurrentOrg } from "@/src/useCurrentOrg"


export default function CaptionSettings () {

  const WEBSITE_TYPES = {
    store:   'online store',
    website: 'website',
  }

  const [ mounted, setMounted ] = useState(false)

  async function loader () {
    const settings = await rpc('orgs.getContentSettings', {}, {throwErros: true})

    if (!settings) return

    let tags = settings.hashtags || []
    if (tags.length === 0) tags = ['']

    setHashtags(tags)
    setPhone(settings.phone)
    setEmail(settings.email)
    setWebsite(settings.website)
    setWebsiteType(settings.websiteType)
    setPronouns(settings.pronouns)

    setMounted(true)
  }

  useEffect(() => loader(), [])

  const org = useCurrentOrg()
  const informalPostsGetContactInfo = !!org?.informalPostsGetContactInfo

  async function toggleInformalPostsGetContactInfo () {
    await rpc('orgs.toggleInformalPostsGetContactInfo', {id: org.id})
    await refreshCurrentOrg()
  }

  const [ loading, setLoading ] = useState(false)

  const [ lastChange, setLastChange ] = useState('')

  const [ editingHashtags,    setEditingHashtags    ] = useState(false)
  const [ editingContactInfo, setEditingContactInfo ] = useState(false)

  const [ pronouns,     setPronouns    ] = useState('')
  const [ phone,        setPhone       ] = useState('')
  const [ email,        setEmail       ] = useState('')
  const [ website,      setWebsite     ] = useState('')
  const [ websiteType,  setWebsiteType ] = useState('website')

  const contactData = [
    phone,
    email,
    website,
  ].filter(s => s)

  const contactText = toSentence([
    phone,
    email,
  ].filter(s => s), 'or')


  const {mobile} = useDeviceInfo()
  const [ hashtags, setHashtagsUnsafe ] = useState([''])

  const nonEmptyHashtags = hashtags.filter(tag => tag.trim() !== '')
  const noHashtags = hashtags.length === 0 || (hashtags.length === 1 && hashtags[0] === '')


  function textToHashtag(value) {
    if (value.match(/^\s+$/))   value = ''
    if (value.length > 0 && value[0] !== '#') value = '#' + value
    return(value)
  }


  function editHashtags () {
    setEditingHashtags(true)
  }


  function handlePronounChange (e) {
    setPronouns(e.target.value)
    setLastChange('pronouns')
  }


  function handleSubmitContactInfo () {
    setEditingContactInfo(false)
    setLastChange('contact')
  }


  function handleHashtagSubmit (e) {
    e.preventDefault()
    setHashtags(tags => uniq( tags.filter(isValidHashtag).map(normalizeHashtag) ) )
    setLastChange('hashtags')
    setEditingHashtags(false)
  }

  // Hashtags can contain letter and numbers, but can't be ALL numbers
  function isValidHashtag (tag) {
    tag = normalizeHashtag(tag)
    return !!tag.match(/^#[a-zA-Z0-9]*[a-zA-Z][a-zA-Z0-9]*$/)
  }


  function normalizeHashtag (tag) {
    if (tag.includes(' ')) tag = upperCaseWords(tag)
    tag = removeInvalidCharacters(tag)
    if (tag.length > 0) tag = '#' + tag
    return tag
  }


  function upperCaseWords (str) {
    return str.replace(/\b./g, v => v.toUpperCase())
  }


  function removeInvalidCharacters (str) {
    return str.replace(/[^a-z0-9]/gi, '')
  }


  function addAnotherHashtag (e) {
    e.preventDefault()
    setHashtags(tags => [...tags, ''])
  }


  function updateHashtag (value, i) {
    value = textToHashtag(value)
    setHashtags(tags => tags.map((tag, j) => i === j ? value : tag))
  }


  function removeHashtag (i) {
    setHashtags(tags => tags.filter((tag, j) => i !== j))
  }


  function setHashtags (tags) {
    if (typeof tags === 'function') {
      tags = tags(hashtags)
    }
    if (tags.length === 0) tags = ['']
    setHashtagsUnsafe(tags)
  }


  useEffect(() => {
    if (!mounted) return
    if (editingHashtags) return
    if (editingContactInfo) return

    save()

    async function save () {
      try {
        setLoading(true)
        await rpc('orgs.setContentSettings', {hashtags: nonEmptyHashtags, phone, email, website, websiteType, pronouns})
      } finally {
        setLoading(false)
      }
    }

  }, [mounted, hashtags, phone, email, website, websiteType, pronouns, editingHashtags, editingContactInfo])


  if (!mounted) return <LoadingSpinner size="medium"/>

  return <>
    <section className="settings p-4 flex-col relative">
      <LoadingOverlay visible={loading}/>

      <div className="flex-col gap-4">

        <div className="gray center small">
          These settings adjust the text in <b>all posts</b>.
        </div>

        <Modal visible={editingHashtags}>
          <div className="p-4 content flex-col gap-4" style={{minWidth: 300}}>
            <div className="flex-row spread">
              <h2 className="m-0">Hashtags</h2>
            </div>

            <div className={classNames("small gray", {tiny: mobile})}>
              <b>Tip:</b> Capitalize each word to make it #EasierToRead
            </div>

            <form className="flex-col gap-5" onSubmit={handleHashtagSubmit}>
              <div className="flex-col gap-2">

                { hashtags.map((hashtag, i) => {
                  return (
                    <div className="control full-width relative" key={i}>
                      <input
                        className="input"
                        placeholder="#TypeYourHashtag"
                        value={hashtag}
                        onChange={e => updateHashtag(e.target.value, i)}
                        autoCapitalize="words"
                        autoFocus={i === hashtags.length - 1}
                        onBlur={e => updateHashtag(normalizeHashtag(e.target.value), i)}
                      />
                      { !noHashtags &&
                        <div
                          className="tiny button is-inverted absolute right px-2 py-4 mt-1 mr-1 red no-border hover_red hover_bg-redder"
                          onClick={() => removeHashtag(i)}
                        >
                          <TrashIcon/>
                        </div>
                      }
                    </div>
                  )
                })}

                <div className="flex-row">
                  <a className="blue hover_blue p-1 m-1 opacity-60 text-sm transition hover_opacity-100" onClick={addAnotherHashtag}>
                    + Add Another Hashtag
                  </a>
                </div>

              </div>

              <div className="flex-row flex-center">
                <button className="button is-primary">
                  #Done 😏
                </button>
              </div>

            </form>

          </div>
        </Modal>


        <div>
          <div>
            <h4>Hashtag{nonEmptyHashtags.length > 1 ? 's' : ''}</h4>
          </div>

          <div className="flex-col gap-2 relative clickable" onClick={editHashtags}>
            <div className="p-2 pr-6 border-gray rounded">
              { nonEmptyHashtags.length ?
                <span>
                  {nonEmptyHashtags.join(' ')}
                </span>
              :
                <span className="light-gray">
                  #AddHashtags to your posts
                </span>
              }
            </div>
            <div className="absolute bottom right opacity-50 py-1 px-3">
              <EditIcon/>
            </div>
          </div>
        </div>


        <div>
          <div>
            <h4>Contact Info</h4>
          </div>

          <div className="flex-col gap-2 relative clickable" onClick={() => setEditingContactInfo(true)}>
            <div className="p-2 pr-6 border-gray rounded">
              { contactData.length ?
                contactData.map((field, i) => (
                  <div key={i} className="overflow-hidden-x ellipsis">
                    {field}
                  </div>
                ))
              :
                <span className="light-gray">
                  Add phone, email, and website
                </span>
              }
            </div>
            <div className="absolute bottom right opacity-50 py-1 px-3">
              <EditIcon/>
            </div>
          </div>
          <div className="my-2 mx-1">
            <Checkbox small disabled={!contactData.length} checked={!informalPostsGetContactInfo} onChange={toggleInformalPostsGetContactInfo}>
              Avoid informal posts (holidays, etc.)
            </Checkbox>
          </div>

          <Modal visible={editingContactInfo}>
            <form className="p-4 flex-col gap-4 content" onSubmit={handleSubmitContactInfo}>
              <div className="center">
                <h2 className="m-0">Contact Info</h2>
              </div>

              <div className="small gray">
                Fill in the fields you want added to your posts.
              </div>

              <div className="flex-col">
                <label className="label">Phone Number</label>
                <div className="control full-width">
                  <input
                    value={phone}
                    name="phone"
                    onChange={e => setPhone(formattedPhoneNumber(e.target.value))}
                    className="input"
                    placeholder="555-555-5555"
                    autoComplete="tel"
                  />
                  { !!phone &&
                    <div className="tiny button is-inverted absolute right px-2 py-4 mt-1 mr-1 red no-border hover_red hover_bg-redder" onClick={() => setPhone('')}>
                      <TrashIcon/>
                    </div>
                  }
                </div>
              </div>

              <div className="flex-col">
                <label className="label">Email Address</label>
                <div className="control full-width">
                  <input
                    value={email}
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    className="input"
                    placeholder="john@example.com"
                    autoComplete="email"
                  />
                  { !!email &&
                    <div className="tiny button is-inverted absolute right px-2 py-4 mt-1 mr-1 red no-border hover_red hover_bg-redder" onClick={() => setEmail('')}>
                      <TrashIcon/>
                    </div>
                  }
                </div>
              </div>

              <div className="flex-col">
                <div className="flex-row my-2 gap-1">
                  <label className="label m-0">URL</label>
                  <div className="flex-row">
                    of your
                    <div className="relative">
                      <div className="p-1 absolute nowrap left flex-center z-down">
                        <span className="invisible">
                          {WEBSITE_TYPES[websiteType]}
                        </span>&nbsp;
                        <span className="text-xs blue ">
                          ▼
                        </span>
                      </div>
                      <select className="p-1 custom-select" value={websiteType} onChange={e => setWebsiteType(e.target.value)}>
                        <option value="store">online store</option>
                        <option value="website">website</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="control full-width">
                  <input
                    value={website}
                    name="website"
                    onChange={e => setWebsite(e.target.value)}
                    className="input with-button"
                    placeholder="example.com"
                    autoComplete="url"
                  />
                  { !!website &&
                    <div className="tiny button is-inverted absolute right px-2 py-4 mt-1 mr-1 red no-border hover_red hover_bg-redder" onClick={() => setWebsite('')}>
                      <TrashIcon/>
                    </div>
                  }
                </div>
              </div>

              <button className="button is-primary fit-content margin-auto px-6">
                Ok
              </button>

            </form>
          </Modal>
        </div>

        <div>
          <div>
            <h4>Grammar</h4>
          </div>

          <div className="relative">
            <div className="select full-width clickable">
              <select className="full-width" value={pronouns} onChange={handlePronounChange}>
                <option value="plural">Plural Pronouns (We, Us, and Our)</option>
                <option value="singular">Singular Pronouns (I, Me, and My)</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <div>
            <h4>Sample Post</h4>
          </div>

          <div className="forcewrap textarea cursor-not-allowed prevent-long-press bg-f8f8f8 flex-col flex-start gap-4" style={{height: 'unset'}}>
            <div>
              <span className={classNames('highlightable', {changed: lastChange === 'pronouns'})}>{pronouns === 'plural' ? 'We' : 'I'}</span> have some big plans for next month.
            </div>
            <div>
              Contact <span className={classNames('highlightable', {changed: lastChange === 'pronouns'})}>{pronouns === 'plural' ? 'us' : 'me'}</span>{contactText ? ` (at ` : ''}<span className={classNames('highlightable', {changed: !!contactText && lastChange === 'contact'})}>{contactText}</span>{contactText ? ')' : ''} for an early sneak peek at <span className={classNames('highlightable', {changed: lastChange === 'pronouns'})}>{pronouns === 'plural' ? 'our' : 'my'}</span> special announcement 😉
            </div>
            <div>
              #News <span className={classNames('highlightable', {changed: nonEmptyHashtags.length > 0 && lastChange === 'hashtags'})}>{nonEmptyHashtags.join(' ')}</span>
            </div>
            { website &&
              <div>
                Visit <span className={classNames('highlightable', {changed: lastChange === 'pronouns'})}>{pronouns === 'plural' ? 'our' : 'my'}</span> {websiteType} at <span className={classNames('highlightable ', {changed: !!website && lastChange === 'contact'})}>{website}</span>
              </div>
            }
          </div>
        </div>

      </div>

    </section>

    <style jsx>{`
      h4 {
        margin: 0.5rem 0;
      }
      .custom-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        background: none;
        font-size: inherit;
        font-family: inherit;
        color: #2f8ab8;
        text-decoration: underline;
        position: relative;
        cursor: pointer;
        outline-color: #2f8ab866;
      }
      .custom-select option {
        font-size: inherit;
        font-family: inherit;
        color: inherit;
      }
      .textarea {
        border-color: #dbdbdb;
        color: #777;
        min-height: unset;
      }
      .highlightable {
        padding: 0rem  0.17rem  0.15rem;
        margin: -0rem -0.17rem -0.15rem;
      }
      input.with-button {
        padding-right: 2.5rem;
      }
      .changed {
        border: 1px solid transparent;
        color: #333;
        border-color: rgba(227, 229, 79, 0.893);
        background-color: #fff400;
      }
    `}</style>
  </>
}
