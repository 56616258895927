import SimpleDate from "@/lib/SimpleDate";
import rpc from "@/src/rpc";
import { useAppRouter } from "@/src/useAppRouter";
import { useEffect, useRef, useState } from "react";
import VisualCatalog from "./VisualCatalog";
import CrossFade from "./CrossFade";
import LoadingSpinner from "./LoadingSpinner";
import SearchIcon from "./icons/legacy/SearchIcon";
import classNames from "classnames";
import CloseIcon from "./icons/CloseIcon";


export default function AddPost({date, onEdit}) {
  const router = useAppRouter()

  useEffect(() => {
    getSearchResults(router.query.search)
    setSearch(router.query.search)
  }, [date, router.query.search])

  date = new SimpleDate(date)

  const searchId = useRef('')
  const searchInput = useRef()
  const retries = useRef(0)
  const [searching, setSearching] = useState(false)
  const [search, setSearch] = useState(router.query.search)
  const [searchKey, setSearchKey] = useState(router.query.search)
  const [currentSearch, setCurrentSearch] = useState('')
  const [error, setError] = useState('')
  const [lastSearch, setLastSearch] = useState('')
  const [events, setEvents] = useState([])


  useEffect(() => {
    // Ignore any events responses after the component unmounts
    return () => searchId.current = null
  }, [])

  async function getSearchResults (newSearch, isRetry) {
    // Create an id for the search, to identify the relevant response
    searchId.current = JSON.stringify({date, newSearch})
    setCurrentSearch(newSearch)

    if (!isRetry) retries.current = 0
    if (retries.current >= 3) {
      setError('Search Failed')
      return
    }
    setError('')
    setSearching(true)

    const response = await rpc('search.events', {
      query: newSearch,
      date: date.toString(),
      requestId: searchId.current,
    })

    // Auto-retry -- mostly useful for dev mode when API server restarts
    if (!response) {
      console.log('Retrying search request')
      retries.current += 1
      setTimeout(() => getSearchResults(newSearch, true), 500)
      return
    }

    // Make sure this response is for the most recent search request, in case
    // the responses arrive out of order.
    if (response.requestId === searchId.current) {
      setEvents(response.results || [])
      setLastSearch(response.query)
      setSearchKey(searchId.current)
      setSearching(false)
    }
  }


  function handleSearch(e) {
    if (e) e.preventDefault()
    searchInput.current.blur()
    router.replace({pathname: router.pathname, query: {...router.query, search}})
    // getSearchResults()
  }


  function newPost (post) {
    let scheduledAt = new Date(date.toString() + "T00:00:00")
    scheduledAt.setHours(9)
    scheduledAt.setMinutes(0)
    scheduledAt.setSeconds(0)

    const earliest = new Date()
    earliest.setMinutes(earliest.getMinutes() + 30)
    earliest.setSeconds(0)

    if (scheduledAt < earliest) {
      scheduledAt = earliest
    }

    post = post || {}
    onEdit({ ...post, scheduledAt, date })
  }

  return <>
    <div className='post-group-editor bg-eee flex flex-col' style={{maxHeight: 'calc(100vh - 2rem)'}}>
      <div className='flex-row spread bg-white shadow z-1'>
        <div>
          &nbsp;
        </div>

        <div className='bold text-center'>
          Add a Post
        </div>

        <div className="flex p-2 pointer" onClick={() => router.goto(date)}>
          <CloseIcon/>
        </div>
      </div>

      <div className="full-height relative p-4 bg-eee flex-grow overflow-auto-y">
        <div className="pb-6 relative">
          <div className="options flex-row gap-2 flex-center">
            <button className="button white bg-blue" onClick={() => newPost()}>
              <span className="hidden sm_contents">Create a </span> Custom Post
            </button>

            <span className="gray inline-block gray">
              or
            </span>

            <div id="search-box">
              <div className="relative mx-auto text-gray">
                <form onSubmit={handleSearch} action=".">
                  <input type="hidden" name="date" value={date || ''} />
                  <div className="relative">
                    <input
                      ref={searchInput}
                      value={search || ''}
                      onChange={e => setSearch(e.target.value)}
                      className="input relative w-full border-2 border-gray bg-white h-10 pl-2 pr-8 rounded-lg focus:outline-none"
                      style={{paddingRight: '3.5rem'}}
                      type="search"
                      name="search"
                    />
                    <div className={classNames("placeholder light-gray absolute pointer-events-none left top p-2 px-3", {hidden: !!search})}>
                      Search <span className="hidden sm_contents"> the Catalog </span>
                    </div>
                    <div className="absolute right top bottom p-1px">
                      <button type="submit" className="shadow-none full-height rounded-l-none button bg-blue white">
                        <SearchIcon/>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>

          <div style={{minHeight: '80vh'}}>
            <CrossFade distance={6} vertical reverse>
              { error ?
                <div className="p-4 my-4 rounded-xl bg-white red bold center">
                  { error }
                </div>
              : searching ?
                <div key={"loading:" + searchId.current} className="bg-white my-6 px-2 py-4 rounded-xl">
                  <LoadingSpinner  medium/>
                  <div className="text-sm center">
                    Searching for {currentSearch || 'posts'}...
                  </div>
                </div>
              : searchKey ?
                <div key={searchKey}>
                  <VisualCatalog
                    events={events}
                    date={date}
                    search={lastSearch}
                    onEdit={newPost}
                  />
                </div>
              :
                null
              }
            </CrossFade>
          </div>

        </div>
      </div>
    </div>


    <style jsx>{`
      .input:focus + .placeholder {
        display: none;
      }
      :global(.small-drawer-container) .options {
        flex-direction: column;
      }
    `}</style>
  </>
}
