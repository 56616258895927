import { useEffect } from 'react'

// Note: callback is a new function on every render that will cause this effect
// (callback/cleanup) to run every render. It's not a big deal but to optimize
// you can wrap callback in `useCallback` before passing it into this hook.

export default function useOnClickOutside(ref, callback) {

  if (!ref) {
    console.warn('Empty ref passed to useOnClickOutside')
  }

  useEffect(
    () => {
      const press = (event) => {
        // Do nothing if the click was on the ref's element or descendents
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        document.addEventListener("mouseup", release)
        document.addEventListener("touchend", release)
      }

      const release = (event) => {
        document.removeEventListener("mouseup", release)
        document.removeEventListener("touchend", release)

        // Do nothing if the click was on the ref's element or descendents
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        callback(event)
      }

      document.addEventListener("mousedown", press)
      document.addEventListener("touchstart", press)

      return () => {
        document.removeEventListener("mousedown", press)
        document.removeEventListener("touchstart", press)
      }
    },
    // Add ref and callback to effect dependencies
    [ref, callback]
  )
}