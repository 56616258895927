import SimpleDate from '@/lib/SimpleDate'
import { titleize } from '@/lib/string'
import { useAppRouter } from '@/src/useAppRouter'
import classNames from 'classnames'


export default function NavButton ({ name, dark }) {

  const router = useAppRouter()
  const active = (
    router.query.popover === name ||
    (name === 'calendar' && !router.query.popover)
  )
  const today = new SimpleDate()

  function handleClick (e) {
    e.preventDefault()
    router.goto(name)
  }

  let classes = classNames('item', {active, dark})

  const label = titleize(name)

  return (
    <div className={classes} onClick={handleClick}>
      <a>
        <span className="icon">
          <svg viewBox="0 0 32 32" width="40" height="40">
            { (name === 'calendar' || name === 'today') ?
              <path strokeWidth="0" d="M22.36,10.2H21.14V9.12A1.52,1.52,0,0,0,19.62,7.6h-.17a1.52,1.52,0,0,0-1.51,1.52V10.2H14.06V9.12A1.52,1.52,0,0,0,12.55,7.6h-.17a1.52,1.52,0,0,0-1.52,1.52V10.2H9.64a1.12,1.12,0,0,0-1.12,1.12v12A1.12,1.12,0,0,0,9.64,24.4H22.36a1.12,1.12,0,0,0,1.12-1.11v-12A1.12,1.12,0,0,0,22.36,10.2Zm-3.45,1.14V9.12a.54.54,0,0,1,.54-.55h.17a.55.55,0,0,1,.55.55v2.79a.55.55,0,0,1-.55.55h-.17a.55.55,0,0,1-.54-.55Zm-7.08,0V9.12a.55.55,0,0,1,.55-.55h.17a.54.54,0,0,1,.54.55v2.79a.55.55,0,0,1-.54.55h-.17a.55.55,0,0,1-.55-.55ZM22.34,23.27H9.66V14H22.34Z"/>
            : name === 'catalog' ?
              <path d="M23.61,22.4,19,17.77A6.09,6.09,0,1,0,17.82,19l4.62,4.62a.83.83,0,0,0,1.17,0A.83.83,0,0,0,23.61,22.4ZM9.8,14.2a4.37,4.37,0,1,1,4.36,4.37A4.37,4.37,0,0,1,9.8,14.2Z"/>
            : name === 'home' ?
              <g>
                <path d="M25.51,16.06,23.15,14V8.58a.51.51,0,0,0-.51-.51h-2a.5.5,0,0,0-.5.51v2.71L16.55,8.11a.85.85,0,0,0-1.1,0l-9,8a.83.83,0,0,0-.07,1.17.82.82,0,0,0,1.17.06L16,9.83l8.41,7.46a.84.84,0,0,0,.55.21.82.82,0,0,0,.55-1.44Z"/>
                <polygon points="9.03 17.39 9.03 24.1 14.18 24.1 14.18 18.78 17.82 18.78 17.82 24.1 22.97 24.1 22.97 17.39 16 11.21 9.03 17.39"/>
              </g>
            : name === 'settings' ?
              <g stroke="none" strokeWidth="0.5" fill="" fillRule="evenodd" strokeLinejoin="bevel">
                <path d="M14.00,3.33 C13.38,3.33 12.78,3.38 12.19,3.48 L11.91,6.52 C11.30,6.69 10.72,6.92 10.19,7.23 L7.84,5.29 C6.85,5.99 5.99,6.85 5.28,7.84 L7.23,10.19 C6.92,10.72 6.68,11.30 6.51,11.91 L3.48,12.19 C3.38,12.78 3.33,13.38 3.33,14.00 C3.33,14.61 3.38,15.22 3.48,15.80 L6.51,16.09 C6.68,16.70 6.92,17.27 7.23,17.81 L5.29,20.15 C5.99,21.14 6.85,22.01 7.84,22.71 L10.19,20.76 C10.72,21.07 11.30,21.31 11.91,21.48 L12.19,24.51 C12.78,24.61 13.38,24.66 14.00,24.66 C14.61,24.66 15.21,24.61 15.80,24.51 L16.08,21.48 C16.69,21.31 17.27,21.07 17.80,20.76 L20.15,22.71 C21.14,22.01 22.00,21.14 22.70,20.15 L20.76,17.81 C21.07,17.27 21.31,16.70 21.48,16.09 L24.51,15.80 C24.61,15.22 24.66,14.61 24.66,14.00 C24.66,13.38 24.61,12.78 24.51,12.19 L21.48,11.91 C21.31,11.30 21.07,10.72 20.76,10.19 L22.71,7.84 C22.00,6.85 21.14,5.99 20.15,5.29 L17.80,7.23 C17.27,6.92 16.69,6.69 16.08,6.52 L15.80,3.48 C15.21,3.38 14.61,3.33 14.00,3.33 L14.00,3.33 Z M14.00,9.93 C16.24,9.93 18.06,11.75 18.06,14.00 C18.06,16.24 16.24,18.06 14.00,18.06 C11.75,18.06 9.93,16.24 9.93,14.00 C9.93,11.75 11.75,9.93 14.00,9.93 Z" id="path2989" transform="translate(14.00, 14.00) scale(0.85) rotate(22.50) translate(-10.00, -11.00)"></path>
              </g>
            :
              null
            }
          </svg>
          {
            name === 'today' &&
            <span className="todays-date">
              { today.dayOfMonth }
            </span>
          }
        </span>
        <span className="label">
          {label}
        </span>
      </a>

      <style jsx>{`
        .item {
          flex: 1;
          border: none;
          text-align: center;
          min-width: 66px;
          transition: all 100ms;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 150ms;
        }
        .icon {
          width: 44px;
          height: 44px;
          transition: all 100ms;
          position: relative;
        }
        .todays-date {
          position: absolute;
          font-size: 10px;
          font-weight: 700;
          top: 18px;
          color: #666695;
        }
        .label {
          font-weight: normal;
          font-size: 0.8rem;
          margin-top: -0.9rem;
          margin-bottom: 0.4rem;
          white-space: nowrap;
          color: #666699;
        }
        .label                    { color:              #666699; }
        .icon                     { fill:               #666699; }
        .item.active              { background-color:   #D3E7EE; }
        .item.active .label       { color:              #31355B; }
        .item.active .icon        { fill:               #31355B;
                                    stroke:             #31355B; }

        .dark.item                { background-color:   #31355B; }
        .dark.item .label         { color:              #FFFFFF;  opacity: 0.5;}
        .dark.item .icon          { fill:               #FFFFFF;  opacity: 0.5;}
        .dark.item.active         { background-color:   #464875; }
        .dark.item.active .label  { opacity: 1 }
        .dark.item.active .icon   { opacity: 1 }

      `}</style>
    </div>
  )

}
