import Link from 'next/link'
import useEnv from '@/src/useEnv'


const LOCAL_URL = /\bhttp:\/\/localhost:\S+/
const URL = /\b(?:https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)[-a-zA-Z0-9()@:%_\+~#?&//=])?/g
const HTTP = /^https?:\/\//


export default function AutoLink ({text}) {

  const {APP_URL, IS_DEVELOPMENT} = useEnv()

  text = text || ''

  // Allow matching on localhost urls (for development mode)
  const isLocal = IS_DEVELOPMENT && !!text.match(LOCAL_URL)
  const regex = isLocal ? LOCAL_URL : URL

  const textBlocks = text.split(regex)
  const matches = text.match(regex)

  let urls = []
  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      let href = matches[i]
      // -----------------------------------------------------------------------
      // NOTE: We should be able to prefix the url with a double slash `//` but
      // that doesn't work with the Next.js Link component, because it collapses
      // the double slash down to a single `/` -- making `//twitter.com` into
      // `/twitter.com`, which is a relative link that resolves to the
      // (`https://app.socialjazz.com/twitter.com`). So we have to prefix with
      // the full `https://` instead.
      // -----------------------------------------------------------------------
      if (!HTTP.test(href)) href = 'https://' + href
      const inAppLink = href.startsWith(APP_URL)

      const label  = inAppLink ? 'Show Me' : matches[i].replace(HTTP, '')
      const target = inAppLink ? '' : '_blank'

      urls.push({href, label, target})
    }
  }

  return <>
    {
      textBlocks.map((str, i) => <span key={i}>
        {str}{ urls[i] &&
          <Link href={urls[i].href} target={urls[i].target} rel="noreferrer">
            <a className="link" >
              {urls[i].label}
            </a>
          </Link>
        }
      </span>
    )}
  </>

}
