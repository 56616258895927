/* eslint @next/next/no-img-element: 0 */  // --> OFF

import SimpleDate from "@/lib/SimpleDate"
import CopyToClipboard from './CopyToClipboard'
import classNames from "classnames"


export default function ShareScreen ({post, hideTitle}) {

  const {caption, image} = post

  const imageUrl = image?.url2 || image?.url || ''

  const extension =  imageUrl ? imageUrl.split('.').pop() : ''
  const nameNormalized = post?.name ? post.name.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-') : new SimpleDate().toString()
  const baseName = [nameNormalized, post.platform].filter(s => s).join('-')
  const imageFilename = [baseName, extension].join('.')
  const imageDownloadUrl = imageUrl ? `${imageUrl}?download=${imageFilename}` : ''
  const isVideo = !!image?.videoUrl

  return <>
    <div className={classNames('center-text')}>
      {
        (!hideTitle) &&
        <h2>Download Post</h2>
      }

      <div className="mb-4">

        { !imageDownloadUrl ?
          null
        :
          <>
            <div className="step">
              <a className="button is-primary margin-auto" href={imageDownloadUrl} download>
                Download the {isVideo ? 'Video' : 'Image'}
              </a>
            </div>
          </>
        }

        <div>
          <CopyToClipboard text={caption} label="Caption">
            <button className="button is-primary full-width">
              Copy Text to Clipboard
            </button>
          </CopyToClipboard>
        </div>

      </div>

    </div>

    <style jsx>{`
      h2 {
        margin-bottom: 1.7rem;
      }

      .content {
        text-align: center;
      }

      .step {
        text-align: left;
        margin: 1rem 0;
        display: flex;
        align-items: center;
      }

      .compact .step {
        margin: 0;
      }

      .is-share-action {
        background: #666599;
        color: white;
        font-size: 0.95rem;
        line-height: 1rem;
        vertical-align: middle;
        margin-right: 0.5rem;
      }

      .hidden-image {
        position: absolute;
        opacity: 0.01;
        z-index: 1;
      }

      .is-share-action,
      .hidden-image {
        border-radius: 6px;
        height: 44px;
        width: 120px;
      }

      .button-and-text {
        display: flex;
        align-items: center;
      }

    `}</style>
  </>
}
