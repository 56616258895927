/* eslint @next/next/no-img-element: 0 */

import { flatten, uniq } from "@/lib/array"
import { pluralCount } from "@/lib/string"
import VideoCameraIcon from "./icons/VideoCameraIcon"


export default function VisualCatalog ({events, search, date, onEdit}) {

  let categories = uniq(flatten(events.map(e => e.categories)))

  // Put the recommended category first
  if (categories.includes('Recommended')) {
    categories = ['Recommended', ...categories.filter(v => v !== 'Recommended')]
  }

  // Split the events into a separate result for each image
  const results = []
  events.forEach(event => {
    if (event.images.length === 0) {
      results.push({
        key:        `${event.id}-no-image`,
        event:      event,
        categories: event.categories,
        caption:    event.captions[0],
      })
    }
    event.images.forEach(image => {
      results.push({
        key:        `${event.id}-${image.id}`,
        event:      event,
        categories: event.categories,
        caption:    event.captions[0],
        imageId:    image.id,
        url:        image.url,
        isVideo:    image.style === 'video',
      })
    })
  })

  return <>
    <div className="gray">
      { !!search ?
        <h2 className="center my-4">
          {pluralCount(results.length, 'search result')} for {search}
        </h2>
      : (!!date && results.length)?
        <p className="center my-4">
          Or pick one of these recommendations...
        </p>
      :
        null
      }
    </div>

    <div className="">
      { categories.map(category => {
        const categoryResults = results.filter(r => r.categories.includes(category))

        return (
          <div key={category} className="mt-4 mb-4 px-3 pb-3 pt-2 rounded-xl border-gray bg-white">
            <div className="text-lg bold mb-2 mt-1">
              {category}
            </div>

            <div className="image-grid">
              { categoryResults.map(result => {
                function handleClick () {
                  onEdit({
                    imageId: result.imageId,
                    caption: result.caption,
                    eventId: result.event.id,
                    images:  result.event.images,
                    date:    date,
                  })
                }

                return (
                  <div className="result relative" key={result.key} onClick={handleClick} draggable="false">
                    { result.isVideo &&
                      <div className="absolute-fill flex-center video-icon">
                        <div className="absolute left bottom center">
                          <div className="bg-black opacity-70 absolute-full rounded-lg"></div>
                          <div className="relative opacity-80 px-2 white flex-row gap-1 flex-align-center sm_flex-centered semi-bold text-xs shadow">
                            <VideoCameraIcon/>
                            <div className="py-1">Video</div>
                          </div>
                        </div>
                      </div>
                    }
                    { result.url ?
                      <img src={ result.url } alt={ result.name } draggable="false"/>
                    :
                      <div className="full square bg-purple">
                        <div className="p-2 text-xs bold white overflow-hidden">
                          { result.caption }
                        </div>
                      </div>
                    }
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>

    <style jsx>{`
      .result {
        transition: all 150ms;
        display: flex;
        box-shadow: 0 0px 1px rgba(0,0,0,0.5);
        cursor: pointer;
        border-radius: 0.25rem;
        overflow: hidden;
        background-color: #666;
      }
      .result img {
        margin: auto;
        max-height: 130px;
        min-height: 50px;
      }
      .square {
        height: 0;
        padding-bottom: 100%;
      }
      @media (hover: hover) {
        .result:hover {
          z-index: 1;
          transform: scale(1.4);
          box-shadow: 0 0px 4px rgba(0,0,0,0.3), 0 4px 11px rgba(0,0,0,0.9);
        }
      }
      .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3px;
      }
      @media screen and (min-width: 550px) {
        .image-grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      @media screen and (min-width: 765px) {
        .image-grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      @media only screen and (min-width: 900px) {
        .image-grid {
          grid-template-columns: repeat(6, 1fr);
        }
      }
    `}</style>
  </>
}
