import Link from 'next/link'
import Head from 'next/head'
import BrandSettings from './BrandSettings'
import PaymentSettings from './PaymentSettings'
import SocialSettings from './SocialSettings'
import CaptionSettings from './CaptionSettings'
import UserManagementSettings from './UserManagementSettings'
import classNames from 'classnames'
import { useAppRouter } from '@/src/useAppRouter'
import NotificationSettings from './NotificationSettings'
import CloseIcon from './icons/CloseIcon'
import HelpSettings from './HelpSettings'
import LeftChevronIcon from './icons/LeftChevronIcon'
import useCurrentOrg from '@/src/useCurrentOrg'
import useCurrentUser from '@/src/useCurrentUser'
import LeftNav from './LeftNav'


const TITLES = {
  brand:          'Branding',
  captions:       'Captions',
  help:           'Help',
  notifications:  'Notifications',
  social:         'Publishing',
  subscription:   'Billing',
  users:          'Team',
}


export default function SettingsScreen ({showBack}) {
  const router = useAppRouter()
  const org = useCurrentOrg()
  const user = useCurrentUser()

  const activeMenu = router.query.menu

  function closeSettings(e) {
    e.preventDefault()
    router.goto('home')
  }

  function handleBack(e) {
    e.preventDefault()
    router.goto('settings')
  }

  if (!org) return null

  return <>
    <Head>
      <title>Settings</title>
    </Head>

    <div className="main content">
      {/* The setting panel renders it's own navigation bar (unlike other Drawers) */}
      <nav role="navigation" aria-label="popup navigation">
        { showBack &&
          <Link href={router.path('settings')}>
            <a className={classNames("back flex-row", {disabled: !activeMenu})}  onClick={handleBack}>
              <div className='py-2'>
                <LeftChevronIcon/>
              </div>
            </a>
          </Link>
        }

        <h2 className={classNames('primary-heading', {hidden: activeMenu})}>
          Settings
        </h2>

        <h2 className={classNames('secondary-heading', {hidden: !activeMenu})}>
          {TITLES[activeMenu]}
        </h2>

        <div className="close-button" onClick={closeSettings}>
          <div style={{padding: "0.5rem 0"}}>
            <CloseIcon/>
          </div>
        </div>
      </nav>

      <div className={classNames("panel-container", {opened: !!activeMenu})}>
        <div className="panels">

          <div className='menu'>
            <LeftNav/>
          </div>


          <div className="details">
            { activeMenu === 'brand' ?
              <BrandSettings/>
            : activeMenu === 'captions' ?
              <CaptionSettings org={org}/>
            : activeMenu === 'help' ?
              <HelpSettings/>
            : activeMenu === 'notifications' ?
              <NotificationSettings/>
            : activeMenu === 'social' ?
              <SocialSettings/>
            : activeMenu === 'subscription' ?
              <PaymentSettings org={org} user={user}/>
            : activeMenu === 'users' ?
              <UserManagementSettings/>
            : (activeMenu === '' || activeMenu === undefined) ?
              null
            :
              <>
                {console.warn(`There is no "${activeMenu}" section defined`)}
              </>
            }

          </div>

        </div>
      </div>
    </div>

    <style jsx>{`
      section {
        margin: 1rem;
      }
      nav {
        text-align: center;
        border-bottom: 1px solid #eee;
        width: 100%;
        height: 44px;
        position: relative;
        position: sticky;
        inset-block-start: 0;
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        z-index: 1;
      }
      nav h2 {
        font-weight: bold;
        font-size: 1.1rem;
        transition: all 300ms;
        position: absolute;
        width: 100%;
        text-align: center;
        margin: 0;
        line-height: 2.7rem;
        pointer-events: none;
      }
      nav .close-button {
        font-size: 0.9rem;
        position: absolute;
        padding: 0 1rem;
        right: 0;
        top: 0;
        color: #485fc7;
        cursor: pointer;
        line-height: 2.7rem;
      }
      nav .close-button:hover {
        color: #363636;
      }
      nav h2.primary-heading.hidden {
        opacity: 0;
        margin-left: -80px;
      }
      nav h2.secondary-heading.hidden {
        opacity: 0;
        margin-left: 80px;
      }
      .panel-container {
        overflow-x: hidden;
      }
      .panels {
        width: 200%;
        display: flex;
        flex-direction: row;
        transition: transform 300ms;
      }
      .panel-container.opened .panels {
        transform: translateX(-50%);
      }
      .menu, .details {
        flex: 1 1 0px;
        width: 0; /* To force them to equal width */
      }
      .menu {
        border-right: 1px solid #eee;
      }
      .back {
        display: block;
        padding: 0 1rem;
        line-height: 2.7rem;
        position: absolute;
        top: 0px;
        left: 0;
        font-size: 0.9rem;
        transition: all 300ms;
      }
      .back.disabled {
        opacity: 0;
        left: 80px;
        pointer-events: none;
      }
    `}</style>
  </>

}
